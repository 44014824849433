import CMSDETAILS from './CMSDETAILS'
import { CMSTABLE } from '../../../_metronic/partials/widgets/tables/CMSTABLE'
import { CmsStationData } from '../js/CMSStationdata'
import CmsPermission from './CmsPermission'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'



const CMSDATA = () => {

  return (
    <div>
         {/* <div className='d-flex justify-content-end mb-5'>
            <Link to='/UserManagment/User/CmsUsers' className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'>
              <KTIcon iconName='arrow-left' className='fs-5' />
            </Link>
          </div> */}
      {/* CARD::DETAILS::STARTS*/}
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center'>
          <h3 className='card-title text-black fs-1 fw-bold'>CMS - USER DETAILS</h3>
        </div>
        <div className='card-body'>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-4 fw-bold '>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                Details
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                Permission
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_6'>
                Station Details
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
              <CMSDETAILS />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
              <CmsPermission />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_6' role='tabpanel'>
              <CMSTABLE cmsstations={CmsStationData} className='mb-5 mb-xl-8' />
            </div>
          </div>
        </div>
      </div>
      {/* CARD::DETAILS::ENDS*/}
    </div>
  )
}

export default CMSDATA
