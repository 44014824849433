import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { KTSVG } from '../../../helpers'
import { postRequest, putRequest } from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required')
    .positive('Amount must be a positive number')
    .integer('Amount must be an integer'),
  note: Yup.string().required("Note must be required")

});


function AddFundstoWallet({ walletId, getWallets }) {
  const [initialValues, setInitialValues] = useState({
    amount: '',
    note: '',
    type: '',
  })
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const res = await putRequest('/wallet/add-funds/' + walletId, values)
      if (res && res.data) {
        toast.success(res.data.message)
        resetForm()
        getWallets()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='wallet_funds_add'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Add funds to the Wallet</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, touched, resetForm }) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Enter the Amount to be added</label>
                        <Field name='amount' className='form-control' type="number" placeholder="Enter amount" />
                        <ErrorMessage name='amount' component='div' className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Enter the Note</label>
                        <Field as='textarea' name='note' className='form-control' type='text-box' placeholder='Enter note' />
                        <ErrorMessage name='note' component='div' className='text-danger' />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                        onClick={() => setFieldValue('type', '1')}
                      >
                        Credit
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-secondary text-primary'
                        data-bs-dismiss='modal'
                        onClick={() => setFieldValue('type', '2')}
                      >
                        Debit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddFundstoWallet
