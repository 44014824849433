/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from '../../API/Api_Instance'
import {CMSUSERSupdate} from '../Helpers/Interfaces'
import {getRequest} from '../Helpers/Requests'

const EmployeeDetails = () => {
  let {pointId} = useParams()
  const [cmsUsers, setCmsUsers] = useState<CMSUSERSupdate | null>(null)
  const [refs, setRefs] = useState([])
  const cmsTableHeadings = {
    heading: 'CMS Users',
    subheading: 'Over 10 Users',
    username: 'UserName',
    email: 'Email',
    mobilenumber: 'Mobile Number',
    status: 'Status',
    group: 'Group Type',
  }
  const getUserById = async () => {
    try {
      const res = await getRequest(`/cms/users/${pointId}`)
      if (res && res.data) {
        setCmsUsers(res.data.data.cmsUser)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllRefferals = async () => {
    try {
      const res = await getRequest(`/cms/users/employee/${pointId}`)

      if (res && res.data) {
        console.log(res.data.refferals)
        setRefs(res.data.refferals)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  const OperatorInformationHeading = {
    username: 'User Name',
    email: 'Email',
    mobilenumber: 'Mobile Number',
    status: 'Status',
  }

  useEffect(() => {
    getUserById()
    getAllRefferals()
  }, [])

  return (
    <div>
      {/* CMS::DETAILS::STARTS*/}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {OperatorInformationHeading.username}
            </label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{cmsUsers?.OperatorName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {OperatorInformationHeading.email}
            </label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{cmsUsers?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {OperatorInformationHeading.mobilenumber}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{cmsUsers?.mobileNumber}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              {OperatorInformationHeading.status}
            </label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-success'>
                {/* {cmsUsers?.status} */}{' '}
                <span className='badge badge-light-success'>ACTIVE ?</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* CMS::DETAILS::ENDS*/}
      <div className='table-responsive'>
        <div className='card-header d-flex p-4'>
          <h3 className='card-title text-dark'>Refferals</h3>
        </div>
        <table className='table table-striped gy-7 gs-7'>
          <thead style={{borderRadius: '10px'}}>
            <tr className='fw-bold text-muted'>
              <th className='w-25px'>
                {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div> */}
              </th>
              <th className='min-w-210px'>{cmsTableHeadings.username}</th>
              <th className='min-w-210px'>{cmsTableHeadings.email}</th>
              <th className='min-w-210px'>{cmsTableHeadings.mobilenumber}</th>
              <th className='min-w-210px'>{cmsTableHeadings.status}</th>
              <th className='min-w-210px'>{cmsTableHeadings.group}</th>
            </tr>
          </thead>
          <tbody>
            {refs?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td></td>
                  <td className='fw-bold'>{item.OperatorName}</td>
                  <td className='fw-bold'>{item.email}</td>
                  <td className='fw-bold'>{item.mobileNumber}</td>
                  <td className='fw-bold'>
                    <span className='badge badge-success'>{item.status ? item.status : 'InActive'}</span>
                  </td>
                  <td className='fw-bold'>{item?.roles[0]?.name?item?.roles[0]?.name:'N/A'}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EmployeeDetails
