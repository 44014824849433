import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {AssignedTable} from '../../../../pages/Helpers/TableHeadings'
import ConfirmModal from '../../../Confirmation/ConfirmModal'
import {deleteRequest} from '../../../../pages/Helpers/Requests'
import toast from 'react-hot-toast'

type Props = {
  tags: any[]
}

function UserDetailsRfIdTags({tags}: Props) {
  const [modalId, setModalId] = useState('')
  const [rfidTags, setRfIdTags] = useState<any>([])
  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/rfids/${modalId}`)
      if (res) {
        toast.success('Deleted SuccessFully ✅')
        const filtered = rfidTags.filter((item: any) => {
          return item.id !== modalId
        })
        setRfIdTags(filtered)
      }
    } catch (error) {
      toast.error('Unable To Delete')
    }
  }

  useEffect(() => {
    setRfIdTags(tags)
  }, [tags])

  return (
    <div className='card-body py-3'>
      <div className='table-responsive'>
        <table className='table align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='ps-4 min-w-20px rounded-start'>{AssignedTable.rfidTag}</th>
              <th className='min-w-230px'>Expiry</th>
              <th className='min-w-230px'>{AssignedTable.mastertag}</th>
              <th className='min-w-230px'>Comment</th>
              <th className='min-w-230px text-center rounded-end'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rfidTags?.map((item: any) => {
              return (
                <tr key={item?.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column ms-5 fw-bold  d-block mb-1 fs-6'>
                        {/* <Link
                          to={'/RfidSinglePage/' + item?.id}
                          className='text-dark fw-bold text-hover-success mb-1 fs-6'
                        > */}
                          {item?.rfIdTag}
                        {/* </Link> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                      {item?.expiryDate}
                    </a>
                  </td>
                  <td className='text-start'>
                    <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                      {item?.masterTag ? item.masterTag?.rfIdTag : 'Not Provided'}
                    </a>
                  </td>
                  <td className='text-start'>
                    <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                      {item?.comments}
                    </a>
                  </td>
                  <td className='text-center'>
                    <Link
                      to={'/RfidEdit/' + item?.id}
                      className='btn btn-icon btn-bg-light btn-active-color-success  btn-sm me-1'
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </Link>
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-success  btn-sm'
                      data-bs-toggle='modal'
                      data-bs-target='#confirm_modal'
                      onClick={() => {
                        setModalId(item?.id)
                      }}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        text='Are you Sure , You want to delete the Consolidate'
        handleSubmit={DeleteHandler}
      />
    </div>
  )
}

export default UserDetailsRfIdTags