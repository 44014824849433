import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {DatePicker} from 'antd'
import {log} from 'console'
import {KTIcon} from '../../../helpers'
import {getDateFormatWithSeconds} from '../../../../functions'
type Props = {
  className: string
  stationId: any
}

const {RangePicker} = DatePicker
const TableWidget97: React.FC<Props> = ({className, stationId}) => {
  const [logs, setLogs] = useState<any[]>([])
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [page, setPage] = useState<any>(1)
  const [loading, setLoading] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const convertJsonToExcel = async () => {
    const dateWiseLogs = await getLogsDateWise()
    console.log(dateWiseLogs)
    const json = dateWiseLogs.map((item: any) => {
      return {
        Id: item?.id,
        TimeStamp: item?.timestamp,
        Origin: item?.from,
        StationId: item?.stationId,
        'Log Type': item?.logType,
        Log: item?.log,
        Error: item?.error === 1 ? true : '-',
      }
    })

    const ws = XLSX.utils.json_to_sheet(json)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })
    saveAs(data, `logs.xlsx`)
  }

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setStartDate(dateStrings[0])
    setEndDate(dateStrings[1])
  }

  const getLogs = async () => {
    try {
      const res = await getRequest('/logs/' + stationId)
      if (res?.status === 200) {
        setLogs(res.data.logs)
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  const getLogsDateWise = async () => {
    try {
      console.log('startDate', startDate)
      console.log('endDate', endDate)
      setDownloading(true)
      const res = await getRequest(
        `/logs/date-wise/${stationId}?from=${new Date(startDate)}&to=${new Date(endDate)}`
      )
      console.log(res)
      if (res?.status === 200) {
        console.log(res.data.logs)
        return res.data.logs
      }
    } catch (error) {
      console.log(error)
    } finally {
      setDownloading(false)
    }
  }

  const getLogsPageWise = async () => {
    try {
      setLoading(true)
      const res = await getRequest(`/logs/page-wise/${stationId}?page=${page}`)

      if (res?.status === 200) {
        setLogs([...logs, ...res.data.logs])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (stationId) {
      getLogsPageWise()
    }
  }, [stationId, page])

  return (
    <div>
      <div className='align-items-center' style={{width: '100%', padding: '10px'}}>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <div className='d-flex me-5'>
              <KTIcon iconName='electricity' className='fs-1 text-primary' />
              <h2 className='text-primary'>Station</h2>
            </div>
            <div className='d-flex me-5'>
              <KTIcon iconName='external-drive' className='fs-1 text-success' />
              <h2 className='text-success'>CMS</h2>
            </div>
            <div className='d-flex me-5'>
              <KTIcon iconName='cross-circle' className='fs-1 text-danger' />
              <h2 className='text-danger'>Error</h2>
            </div>
          </div>
          <div>
            <RangePicker
              showTime={true}
              onChange={handleDateChange}
              format='YYYY-MM-DD HH:mm:ss'
              style={{marginRight: '10px', flex: '1'}}
            />
            <button
              className='btn btn-sm btn-light-success'
              style={{flex: '0 0 auto'}}
              onClick={convertJsonToExcel}
              disabled={!startDate || !endDate || downloading}
            >
              {downloading ? 'Preparing File logs.xlsx' : 'Download'}
            </button>
          </div>
        </div>
      </div>
      <div className={`card ${className}`} style={{maxWidth: '100%', overflowX: 'auto'}}>
        <div className='card-body py-2'>
          <div className='table-responsive'>
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              style={{tableLayout: 'fixed'}}
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th style={{width: '15%'}}>Date Time</th>
                  <th style={{width: '18%'}}>Response Type</th>
                  <th style={{width: '68%'}}>Response Json</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((item: any, index: number) => {
                  const logData = JSON.parse(item?.log)
                  return (
                    <tr
                      key={index}
                      className={`${
                        item?.from === 'Station'
                          ? 'text-primary'
                          : item?.from === 'CMS' && item?.error == 0
                          ? 'text-success'
                          : ''
                      } ${item?.error == 1 ? 'text-danger' : ''}`}
                    >
                      <td className='fw-bold fs-6'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-center flex-column'>
                            {/* {JSON.parse(item?.timestamp)} */}

                            {getDateFormatWithSeconds(new Date(item?.timestamp))}
                          </div>
                        </div>
                      </td>
                      <td>
                        <p
                          className='fw-bold d-block fs-6'
                          style={{
                            maxWidth: '100%',
                            display: 'block',
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {Array.isArray(logData) && typeof logData[2] === 'string'
                            ? logData[2]
                            : ''}
                        </p>
                      </td>
                      <td>
                        <p
                          className='fw-bold d-block fs-6'
                          style={{
                            maxWidth: '100%',
                            display: 'block',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                          }}
                        >
                          {JSON.stringify(logData)}
                        </p>
                      </td>
                    </tr>
                  )
                })}
                <div className='d-flex justify-center items-center'>
                  <button
                    className='btn btn-sm btn-secondary'
                    onClick={() => {
                      setPage(page + 1)
                    }}
                  >
                    {loading ? 'Loading....' : 'Load More.....'}
                  </button>
                </div>
              </tbody>
            </table>
          </div>
        </div>
        <ConfirmModal
          text='Are you Sure. You want to delete the CMS User ? '
          handleSubmit={() => {}}
        />
      </div>
    </div>
  )
}

export {TableWidget97}
