import React from 'react';
import { KTSVG } from '../../../helpers';

interface ReportImageModalProps {
  imageSrc: string;  
  onClose: () => void;  
}

const ReportImageModal: React.FC<ReportImageModalProps> = ({ imageSrc, onClose }) => {
  return (
    <div className='modal fade' tabIndex={-1} id='Report_Modal' role='dialog' aria-labelledby='modalTitle' aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='modalTitle'>Report Image</h5>
            <button
              type='button'
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={onClose}
              aria-label='Close'
              data-bs-dismiss='modal'
            >
              <KTSVG
                path='media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </button>
          </div>
          <div className='modal-body'>
            <img
              src={imageSrc  ? imageSrc : "https://images.unsplash.com/photo-1694889649703-e86125c14fe2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} 
              alt='Report'
              className='img-fluid'
               
            />
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default ReportImageModal;
