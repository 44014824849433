/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link, useParams} from 'react-router-dom'
import {CmsStationData} from '../../../../app/pages/js/CMSStationdata'
import Top from '../../../../app/modules/Chargestations/Charge/components/Top'
import axios from 'axios'
import {toast} from 'react-toastify'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
type Props = {
  className: string
  cmsstations: {}
}

export interface cmsstations {
  id: any
  operatorname: String
  stationame: String
  chargebox: String
  type: String
  connectors: String
  porttype: String
  location: String
  status: String
}

export const Stationslist = {
  heading: 'List Of St',
  subheading: 'Over 20 new Stations',
  operatorname: 'Operatorname',
  stationame: 'Station Name',
  chargebox: 'ChargeBox Id',
  type: 'Types',
  connectors: 'Connectors',
  porttype: 'Port Type',
  location: 'Location',
  status: 'Status',
}

const CMSTABLE: React.FC<Props> = ({className}) => {
  const [stations, setStations] = useState([])
  const [cms, setCms] = useState<any>({})
  const {pointId} = useParams()
  const [modalId, setModalId] = useState('')

  const getStations = async () => {
    getRequest(`/cms/users/${pointId}`)
      .then((res) => {
        setStations(res?.data.data.cmsUser.stations)
        setCms(res?.data.data.cmsUser)
      })
      .catch((err) => console.log(err))
  }

  const DeleteHandler = (id) => {
    deleteRequest(`/stations/${modalId}`)
      .then(() => {
        toast.success('Deleted SuccessFully')
        getStations()
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  useEffect(() => {
    getStations()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-150px ps-4 rounded-start'>{Stationslist.operatorname}</th>
                <th className='min-w-150px'>{Stationslist.stationame}</th>
                <th className='min-w-150px'>{Stationslist.chargebox}</th>
                <th className='min-w-150px'>{Stationslist.type}</th>
                <th className='min-w-150px'>{Stationslist.connectors}</th>
                <th className='min-w-150px'>{Stationslist.porttype}</th>
                <th className='min-w-150px'>{Stationslist.location}</th>
                <th className='min-w-150px'>{Stationslist.status}</th>
                <th className='min-w-150px text-center rounded-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {stations.map((item: any, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column ps-4'>
                          <Link
                            to={`/OperatorInformation/${cms?.id}`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {cms?.OperatorName}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            user
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <Link
                        to={'/StationDetails/' + item?.id}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.stationName}
                      </Link>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.id}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.charge_type}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Charge</span>
                    </td>
                    <td>
                      <span className='badge badge-light-info fs-6 fw-semibold'>
                        {/* {item?.connectors?.length} */}
                      </span>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {/* {item?.connectors[0]?.port_type} */}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Ports</span>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.location?.address}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>India</span>
                    </td>
                    <td>
                      <span
                        className={
                          item?.status == 'Active'
                            ? 'badge fs-7 fw-semibold badge-light-success'
                            : 'badge fs-7 fw-semibold badge-light-danger'
                        }
                      >
                        {item?.status}
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                      >
                        <KTIcon iconName='scan-barcode' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                      >
                        <KTIcon iconName='arrows-circle' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#confirm_modal'
                        onClick={() => {
                          setModalId((prev) => {
                            return item.id
                          })
                        }}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
            <ConfirmModal
              text='Are you sure you want to delete this station?'
              handleSubmit={DeleteHandler}
            />
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {CMSTABLE}
