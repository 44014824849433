/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Field} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import ConnectorDetails from './ConnectorDetails'
import {getRequest} from '../Helpers/Requests'
import {useParams} from 'react-router-dom'
const stateOptions = [
  {value: 'AN', label: 'Andaman and Nicobar Islands'},
  {value: 'AP', label: 'Andhra Pradesh'},
  {value: 'AR', label: 'Arunachal Pradesh'},
  {value: 'AS', label: 'Assam'},
  {value: 'BR', label: 'Bihar'},
  {value: 'CH', label: 'Chandigarh'},
  {value: 'CT', label: 'Chhattisgarh'},
  {value: 'DL', label: 'Delhi'},
  {value: 'GA', label: 'Goa'},
  {value: 'GJ', label: 'Gujarat'},
  {value: 'HP', label: 'Himachal Pradesh'},
  {value: 'HR', label: 'Haryana'},
  {value: 'JH', label: 'Jharkhand'},
  {value: 'JK', label: 'Jammu and Kashmir'},
  {value: 'KA', label: 'Karnataka'},
  {value: 'KL', label: 'Kerala'},
  {value: 'LA', label: 'Ladakh'},
  {value: 'LD', label: 'Lakshadweep'},
  {value: 'MH', label: 'Maharashtra'},
  {value: 'MN', label: 'Manipur'},
  {value: 'ML', label: 'Meghalaya'},
  {value: 'MZ', label: 'Mizoram'},
  {value: 'NL', label: 'Nagaland'},
  {value: 'OD', label: 'Odisha'},
  {value: 'PB', label: 'Punjab'},
  {value: 'PY', label: 'Puducherry'},
  {value: 'RJ', label: 'Rajasthan'},
  {value: 'SK', label: 'Sikkim'},
  {value: 'TN', label: 'Tamil Nadu'},
  {value: 'TS', label: 'Telangana'},
  {value: 'TR', label: 'Tripura'},
  {value: 'UP', label: 'Uttar Pradesh'},
  {value: 'UK', label: 'Uttarakhand'},
  {value: 'WB', label: 'West Bengal'},
]

const ChargerGeneralDetailsOne = ({errors, values, setFieldValue, touched}) => {
  const [hubs, setHubs] = useState<any>([])
  const [selectedState, setSelectedState] = useState<any>('')
  const [count, setCount] = useState<any>()
  const [type, setType] = useState<any>('DC')
  const [hub, setHub] = useState<any>({})
  const {stationId} = useParams()

  const getHub = async () => {
    try {
      const res = await getRequest(`/hub/${stationId}?include=none`)
      if (res?.status === 200) {
        setHub(res.data.data.hub)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getStationCount = async () => {
    try {
      const res = await getRequest('/count/state-wise/' + selectedState)
      if (res?.status === 200) {
        generateFormattedString(selectedState, res.data.data.count + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function formatCount(count, length) {
    return count.toString().padStart(length, '0')
  }

  function generateFormattedString(state, count) {
    const formattedCount = formatCount(count, 4)
    console.log(count)
    console.log(`${'ZECT'}${type}${state}${formattedCount}`)
    setFieldValue('stationId', `${'ZECT'}${type}${state}${formattedCount}`)
    return `${'ZECT'}${type}${state}${formattedCount}`
  }

  useEffect(() => {
    if (count !== null && count !== undefined && count != '') {
      generateFormattedString(selectedState, count)
    }
  }, [count, type])

  useEffect(() => {
    getHub()
  }, [])

  useEffect(() => {
    if (selectedState !== null && selectedState !== undefined && selectedState !== '') {
      getStationCount()
    }
  }, [selectedState, type])

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-70px w-70px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/car-01.svg')}
                alt='Metronic'
                className='p-3'
              />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Charger Details
            </a>
          </div>

          <div className='card-toolbar m-0'></div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <input
                      type='text'
                      className='form-control'
                      aria-label='Select example'
                      disabled
                      value={hub?.stationName}
                      placeholder='Station'
                    />
                    <span className='text-danger fs-5'>
                      {touched.hubId && errors.hubId && errors.hubId}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      aria-label='Select example'
                      placeholder='Name of Station'
                      name='name'
                    />
                    <span className='text-danger fs-5'>
                      {touched.name && errors.name && errors.name}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='communication'
                    >
                      <option value='Communication Medium'>Communication Medium</option>
                      <option value='Wifi'>Wifi</option>
                      <option value='3G/4G'>3G/4G</option>
                      <option value='5G'>5G</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.communication && errors.communication && errors.communication}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='charge_type'
                      onChange={(e: any) => {
                        setFieldValue('charge_type', e.target.value)
                        setType(e.target.value)
                      }}
                    >
                      <option>Charge Type</option>
                      <option value='AC'>AC</option>
                      <option value='DC'>DC</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.charge_type && errors.charge_type && errors.charge_type}
                    </span>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='state'
                      value={selectedState}
                      onChange={(e: any) => {
                        setSelectedState(e.target.value)
                        setFieldValue('state', e.target.value)
                      }}
                    >
                      <option value=''>--Select a state--</option>
                      {stateOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.state && errors.state && errors.state}
                    </span>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      placeholder='StationID'
                      name='stationId'
                      disabled={true}
                    />
                    <span className='text-danger fs-5'>
                      {touched.stationId && errors.stationId && errors.stationId}
                    </span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      aria-label='Select example'
                      name='status'
                    >
                      <option value=''>Status</option>
                      <option value='Active'>Active</option>
                      <option value='InActive'>InActive</option>
                    </Field>
                    <span className='text-danger fs-5'>
                      {touched.status && errors.status && errors.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div>
                  <ConnectorDetails errors={errors} values={values} touched={touched} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChargerGeneralDetailsOne
