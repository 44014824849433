import React from 'react'
import StationImageUploadModal from './Map/StationImageUploadModal'
import './custom.css'
import toast from 'react-hot-toast'
import {KTIcon} from '../../../_metronic/helpers'
import {deleteRequest} from '../Helpers/Requests'
import { useParams } from 'react-router-dom'

const StationImages = ({images,stationId}) => {

  const handleImageDelete = async (id:any) => {
    try {
      const res = await deleteRequest('/hub/delete/gallery-image/' + id)
      if (res?.status === 200) {
        toast.success(res?.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!images || images.length === 0) {
    return (
      <div className='container mt-4'>
        <div className='d-flex justify-content-end '>
          <button
            className='m-3 btn btn-success'
            data-bs-toggle='modal'
            data-bs-target='#upload_images'
          >
            Upload Images
          </button>
        </div>
        <h5 className='text-center mt-4'>No Station Images</h5>
        <StationImageUploadModal stationId={stationId} />
      </div>
    )
  }

  return (
    <div className='container mt-4'>
      <div className='d-flex justify-content-end '>
        <button
          className='m-3 btn btn-success'
          data-bs-toggle='modal'
          data-bs-target='#upload_images'
        >
          Upload Images
        </button>
      </div>
      <div className='row g-3'>
        {images.map((image: any, index: any) => (
          <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
            <div className='card card-hover'>
              <div className='d-flex justify-content-end'>
                <button
                  className='btn del-button'
                  onClick={() => {
                    handleImageDelete(image?.id)
                  }}
                >
                  <KTIcon iconName='trash' className='fs-1 p-0 m-0' />
                </button>
              </div>
              <img
                src={image.url}
                alt={`Station ${index}`}
                className='card-img-top'
                style={{objectFit: 'contain', height: '200px'}}
              />
            </div>
          </div>
        ))}
      </div>
      <StationImageUploadModal stationId={stationId} />
    </div>
  )
}

export default StationImages
