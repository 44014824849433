/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {Field, FieldArray} from 'formik'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getRequest} from '../Helpers/Requests'
import {useParams} from 'react-router-dom'
const ConnectorDetails = ({errors, values, touched}) => {
  const [tariffs, setTariffs] = useState<any>([])
  const [connectorTypes, setConnectorTypes] = useState([])
  const params = useParams()

  const getTariffs = async () => {
    try {
      const res = await getRequest(`/tariff/hub/${params?.stationId}`)
      if (res && res.data) {
        setTariffs(res.data.data)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getConnectorTypes = async () => {
    try {
      console.log(values.charge_type)
      const res = await getRequest(`/connector-type/?type=${values.charge_type}&attributes=id,name`)
      if (res && res.data) {
        console.log(res.data)
        setConnectorTypes(res.data.connectorTypes)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTariffs()
  }, [])
  useEffect(() => {
    console.log(values.charge_type)
    if (values.charge_type && values.charge_type !== '') {
      getConnectorTypes()
    }
  }, [values.charge_type])

  return (
    <div>
      <div className='card card-custom mt-15 mb-10'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-55px w-55px bg-light me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/connector.svg')}
                alt='connector'
                className='p-3'
              />
            </div>
            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Connector Details
            </a>
          </div>
          <div className='card-toolbar m-0'></div>
        </div>
        <div className='card-body'>
          <FieldArray
            name='connectors'
            render={(arrayHelpers) => (
              <div>
                {values.connectors.map((connector, i) => (
                  <div key={i}>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='row'>
                          <div className='col-md-4'>
                            <div className='mb-10'>
                              <Field
                                className='form-control'
                                aria-label='Select example'
                                name={`connectors[${i}].connector_ID`}
                                placeholder='Connector ID'
                                disabled={true}

                                // value={i+1}
                              ></Field>
                              <span className='text-danger fs-5'>
                                {/* {touched.connector_ID && errors.connector_ID && errors.connector_ID} */}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='mb-10'>
                              <Field
                                as='select'
                                className='form-select'
                                aria-label='Select example'
                                name={`connectors[${i}].port_type`}
                              >
                                <option value=''>Select Port Type</option>
                                {connectorTypes.map((item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  )
                                })}
                              </Field>
                              <span className='text-danger fs-5'></span>
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='mb-10'>
                              <Field
                                type='text'
                                className='form-control'
                                placeholder='Power Output (Kw)'
                                name={`connectors[${i}].power_output`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-4'>
                            <div className='mb-10'>
                              <Field
                                as='select'
                                className='form-select'
                                aria-label='Select example'
                                name={`connectors[${i}].tariffId`}
                              >
                                <option>Select Tariff</option>
                                {tariffs.map((e, i) => {
                                  return (
                                    <option key={i} value={e.id}>
                                      {e.name}
                                    </option>
                                  )
                                })}
                              </Field>
                              {/* <span className='text-danger fs-5'>
                                {errors.tariffId && errors.tariffId}
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-light-success'
                    onClick={() => {
                      if (values.connectors.length < 4) {
                        arrayHelpers.push({
                          connector_ID: values.connectors.length + 1,
                          port_type: '',
                          power_output: '',
                          tariffId: '',
                        })
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          />

          {/*TABEL: ONE: Ends: HERE */}
        </div>
      </div>
    </div>
  )
}

export default ConnectorDetails
