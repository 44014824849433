/* eslint-disable */
import React, {useEffect, useState} from 'react'
const UsersCars = ({className, userId, cars}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-210px ps-3'>Brand</th>
                <th className='min-w-210px'>Model</th>
                <th className='min-w-210px'>Charger Type</th>
                <th className='min-w-210px'>Vin No.</th>
              </tr>
            </thead>
            <tbody>
              {cars?.map((car: any, index: number) => (
                <tr key={index}>
                  <td className='ps-3'>{car?.model?.brand?.brandName}</td>
                  <td>{car?.model?.name}</td>
                  <td>{car?.model?.connectorType?.map((item: any) => item.name).join(',')}</td>
                  <td>{car?.vinNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {UsersCars}