import * as Yup from 'yup'

export const CarValidation = Yup.object({
    brandId: Yup.number().required('Please select car brand name'),
    name: Yup.string().required('Please select car  name'),
    carType: Yup.string().required('select car type'),
    connectorType: Yup.array()
    .of(Yup.string().required('Connector type is required'))
    .min(1, 'At least one connector type is required')
    .required('Select car connector type'),
})
