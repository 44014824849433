import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import toast from 'react-hot-toast'
import {getRequest, putRequest} from '../../../app/pages/Helpers/Requests'
import {KTSVG} from '../../helpers'

// Validation schema for the form
const validationSchema = Yup.object().shape({
  code: Yup.string().required('Vendor Error Code is required'),
  info: Yup.string().required('Info is required'),
  status: Yup.string().required('Charger Status is Required'),
  errorCode: Yup.string().required('Error Code is Required'),
  responseText: Yup.string().required('Response Message is Required'),
})

function UpdateErrorCode({fetchData, modalId, setModalId}) {
  const [initialValues, setInitialValues] = useState({
    code: '',
    info: '',
    status: '',
    errorCode: '',
    responseText: '',
  })
  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await putRequest(`/vendor-error-code/${modalId}`, values)
      if (res && res.data) {
        resetForm()
        fetchData()
        setModalId(undefined)
        toast.success(res.data.message)
      }
    } catch (error: any) {
      console.error(error)
      toast.error(error.response?.data?.message || 'An error occurred')
    }
  }

  const getById = async () => {
    try {
      const res = await getRequest(`/vendor-error-code/${modalId}`)
      if (res?.status === 200) {
        setInitialValues(res.data.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (modalId) {
      getById()
    }
  }, [modalId])

  return (
    <div className='modal fade' tabIndex={-1} id='update_error_code'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Update Error Code</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Vendor Error Code</label>
                      <Field
                        placeholder='Vendor Error Code'
                        name='code'
                        className='form-control'
                        type='number'
                      />
                      <ErrorMessage name='code' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Info</label>
                      <Field placeholder='Info' name='info' className='form-control' />
                      <ErrorMessage name='info' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Charger Status</label>
                      <Field as='select' name='status' className='form-control'>
                        <option value=''>Select Charger Status</option>
                        {[
                          'Available',
                          'Preparing',
                          'Charging',
                          'SuspendedEVSE',
                          'SuspendedEV',
                          'Finishing',
                          'Reserved',
                          'Unavailable',
                          'Faulted',
                        ].map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name='status' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Error Code</label>
                      <Field placeholder='Error Code' name='errorCode' className='form-control' />
                      <ErrorMessage name='errorCode' component='div' className='text-danger' />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Response Message</label>
                      <Field
                        as='textarea'
                        placeholder='Response Message'
                        name='responseText'
                        className='form-control'
                      />
                      <ErrorMessage name='responseText' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateErrorCode
