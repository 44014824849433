import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { getRequest, putRequest } from './Helpers/Requests';
import { newRfidValidation } from './js/NewRfidValidation';

const RfidEdit = () => {
  const { pointId } = useParams();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [masterTags, setMasterTags] = useState([]);
  const [initialValues, setInitialValues] = useState({
    rfIdTag: '',
    userId: '',
    expiryDate: '',
    masterRfidTag: '',
    comments: '',
  });

  // Fetch RFID details for editing
  const fetchRfIdDetails = async () => {
    try {
      const res = await getRequest(`/rfids/${pointId}`);
      if (res?.data?.data?.RfIdTag) {
        const rfidTag = res.data.data.RfIdTag;
        setInitialValues({
          rfIdTag: rfidTag.rfIdTag || '',
          userId: rfidTag.userId || '',
          expiryDate: rfidTag.expiryDate || '',
          masterRfidTag: rfidTag.masterRfidTag || '',
          comments: rfidTag.comments || '',
        });
        // Fetch master RFID tags for the user
        await fetchMasterTags(rfidTag.userId);
      }
    } catch (error) {
      console.error('Error fetching RFID details:', error);
    }
  };

  // Fetch users
  const fetchUsers = async () => {
    try {
      const res = await getRequest('/ev/users/');
      if (res?.data?.data) {
        setUserList(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch master tags based on userId
  const fetchMasterTags = async (userId: string) => {
    try {
      const res = await getRequest(`/rfids/master/${userId}`);
      if (res?.data?.data) {
        setMasterTags(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching master tags:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (values: any) => {
    console.log('Submitting form with values:', values); // Debug log
    try {
      const res = await putRequest(`/rfids/${pointId}`, values);
      if (res?.data) {
        toast.success('RFID Tag Updated Successfully ✅');
        setTimeout(() => navigate('/Evusers/EV/RfidTags'), 1000);
      }
    } catch (error) {
      toast.error('Failed to Update RFID Tag ❌');
      console.error('Error in PUT request:', error);
    }
  };

  useEffect(() => {
    fetchRfIdDetails();
    fetchUsers();
  }, [pointId]);

  return (
    <div>
      <Toaster position='top-right' reverseOrder={true} />
      <div className='card card-custom'>
        <div className='card-header text-center'>
          <h3 className='card-title text-black'>EDIT RFID TAG</h3>
        </div>
        <div className='card-body'>
          <Formik
            initialValues={initialValues}
            validationSchema={newRfidValidation}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, setFieldValue, values }) => (
              <Form>
                <div className='row'>
                  {/* RFID Tag */}
                  <div className='col-md-6 mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      name='rfIdTag'
                      placeholder='RFID Tag'
                      value={values.rfIdTag} // Formik will handle this field now
                      disabled
                    />
                    <span className='text-danger fs-5'>{errors.rfIdTag}</span>
                  </div>

                  {/* User Selection */}
                  <div className='col-md-6 mb-10'>
                    <Field
                      as='select'
                      className='form-select'
                      name='userId'
                      onChange={async (e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedUserId = e.target.value;
                        setFieldValue('userId', selectedUserId);
                        await fetchMasterTags(selectedUserId); // Fetch master tags when user is selected
                      }}
                      disabled={true} // Disable the user select dropdown
                    >
                      <option value=''>Select User</option>
                      {userList.map((user: any) => (
                        <option key={user.id} value={user.id}>
                          {`${user.firstName} ${user.lastName}`}
                        </option>
                      ))}
                    </Field>
                    <span className='text-danger fs-5'>{errors.userId}</span>
                  </div>
                </div>

                <div className='row'>
                  {/* Expiry Date */}
                  <div className='col-md-6 mb-10'>
                    <Field
                      type='date'
                      className='form-control'
                      name='expiryDate'
                      value={values.expiryDate}
                    />
                    <span className='text-danger fs-5'>{errors.expiryDate}</span>
                  </div>

                  {/* Master RFID Tag */}
                  <div className='col-md-6 mb-10'>
                    <Field as='select' className='form-select' name='masterRfidTag' disabled={true}>
                      <option value=''>Select Master RFID Tag</option>
                      {masterTags?.map((tag: any) => (
                        <option key={tag.id} value={tag.id}>
                          {tag.rfIdTag}
                        </option>
                      ))}
                    </Field>
                    <span className='text-danger fs-5'>{errors.masterRfidTag}</span>
                  </div>
                </div>

                {/* Comments */}
                <div className='mb-10'>
                  <Field
                    as='textarea'
                    className='form-control'
                    name='comments'
                    placeholder='Comments'
                    rows='3'
                    value={values.comments} // Ensure Formik manages this field
                  />
                  <span className='text-danger fs-5'>{errors.comments}</span>
                </div>

                {/* Buttons */}
                <div className='d-flex justify-content-end'>
                  <button type='reset' className='btn btn-light-danger me-2'>
                    Reset
                  </button>
                  <button type='submit' className='btn btn-light-success me-2'>
                    Update
                  </button>
                  <Link to='/Evusers/EV/RfidTags'>
                    <button type='button' className='btn btn-light-primary'>
                      Back
                    </button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RfidEdit;
