/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { TableOnes } from '../../../../app/pages/js/TableOne'
import { useParams } from 'react-router-dom'
import { DeviceTransactions } from '../../../../app/pages/js/DeviceTransaction'
import { getRequest } from './../../../../app/pages/Helpers/Requests'
import TransactionDetailsModal from '../../../../app/pages/Components/TransactionDetailsModal'
import { error } from 'console'
import PaginatedItems from '../../../../app/UI/PaginatedItems'

type Props = {
  className: String
  DT: {}
  stationId: any
}
export interface dtUpdates {
  config: String
  value: String
  description: String
  access: String
}
export const DTable = {
  id: 'Transaction Id',
  connector: 'Connector Id',
  username: 'UserName',
  start: 'Start Date',
  stop: 'Stop Date',
  duration: 'Duration',
  meter: 'Total Meter value(Wh)',
  platform: 'Platform',
  stopfrom: 'Stop From',
  status: 'Status',
}

const UserChargingTransactions = ({ className, userId }) => {
  let { pointId } = useParams()
  // var pid: number = parseInt(pointId);
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [transactions, setTransactions] = useState<any>([])
  const [limit, setLimit] = useState<number>(8)
  const [page, setPages] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(0)
  var result = DeviceTransactions.find(function (e) {
    return e.id == pointId
  })

  const getDeviceTransactions = async () => {
    try {
      const res = await getRequest('/device-transaction/user/' + `${userId}?page=${page}&limit=${limit}`)
      console.log(res)
      if (res?.status === 200) {
        setTransactions(res.data.data)
        setTotalPage(res.data.pagination?.totalPages)
        console.log(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPlatformStatus = (platform: string) => {
    if (platform === 'IOS') {
      return 'badge fs-7 fw-semibold badge-light-primary'
    } else if (platform === 'ANDROID') {
      return 'badge fs-7 fw-semibold badge-light-info'
    } else if (platform === 'WEB') {
      return 'badge fs-7 fw-semibold badge-light-success'
    } else {
      return 'badge fs-7 fw-semibold badge-light-danger'
    }
  }

  useEffect(() => {
    if (userId !== null && userId !== undefined && userId !== '') {
      getDeviceTransactions()
    }
  }, [userId, page])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      {/* begin::Table container */}
      <div className='table-responsive'>
        <table className='table table-row-dashed table-striped gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-black border-bottom-2 border-gray-200'>
              <th className='min-w-210px'>{DTable.id}</th>
              <th className='min-w-210px'>Station Id</th>
              <th className='min-w-210px'>{DTable.start}</th>
              <th className='min-w-210px'>{DTable.stop}</th>
              <th className='min-w-210px'>{DTable.duration + '(Mins)'}</th>
              <th className='min-w-210px'>{DTable.meter}</th>
              <th className='min-w-210px'>Amount(Rs)</th>
              <th className='min-w-210px'>Reason</th>
              <th className='min-w-210px'>{DTable.platform}</th>
              <th className='min-w-210px'>{DTable.stopfrom}</th>
              <th className='min-w-210px'>{DTable.status}</th>
            </tr>
          </thead>
          <tbody>
            {transactions &&
              transactions?.map((transaction: any, index: any) => {
                const updatedAt = new Date(transaction.updatedAt)
                const createdAt = new Date(transaction.createdAt)
                const timeDifference = updatedAt.getTime() - createdAt.getTime()
                const secondsDifference = timeDifference / 1000;
                const localStartTime = new Date(transaction?.createdAt).toLocaleTimeString();
                const localStopTime = new Date(transaction?.updatedAt).toLocaleTimeString();
                return (
                  <tr key={index}>
                    <td>
                      <a
                        data-bs-toggle='modal'
                        data-bs-target='#transaction_details'
                        className='cursor-pointer text-black'
                        onClick={() => {
                          setSelectedTransaction(transaction.transactionId)
                        }}
                      >
                        {transaction?.transactionId}
                      </a>
                    </td>
                    <td>{transaction?.stationId}</td>
                    <td>{transaction?.startDate}</td>
                    <td>{transaction?.stopDate}</td>
                    {/* <td>
                      {transaction?.startDate?.split('T')[0] +
                        ' ' +
                        localStartTime}
                    </td>
                    <td>
                      {transaction?.stopDate &&
                        transaction?.stopDate?.split('T')[0] +
                        ' ' +
                        localStopTime}
                    </td> */}
                    <td>
                      {(secondsDifference / 60).toFixed(1)}
                    </td>
                    <td>{transaction?.total_wh}</td>
                    <td>{transaction?.price}</td>
                    <td>{transaction?.reason}</td>
                    <td>
                      <span className={getPlatformStatus(transaction?.platform)}>
                        {transaction?.platform}
                      </span>
                    </td>
                    <td>
                      <span className={getPlatformStatus(transaction?.stopFrom)}>
                        {transaction?.stopFrom}
                      </span>
                    </td>
                    <td>
                      {transaction?.status == 0 ? (
                        <a href='#' className={`btn btn-sm btn-warning me-3`}>
                          Charging
                        </a>
                      ) : (
                        <a href='#' className={`btn btn-sm btn-success me-3`}>
                          Completed
                        </a>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
          <TransactionDetailsModal transactionId={selectedTransaction} />
        </table>
      </div>
      <PaginatedItems setPage={setPages} pages={totalPages} currentPage={page} />
    </div>
  )
}

export { UserChargingTransactions }
