/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import PaginatedItems from '../../../../app/UI/PaginatedItems'

type Props = {
  className?: String
}

const TotalConnectors: React.FC<Props> = ({className}) => {
  const [connectors, setConnectors] = useState([])
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<any>(8)
  const [pages, setPages] = useState<any>(0)

  const getAllConnectors = async () => {
    try {
      const res = await getRequest(`/chargebox/connector?page=${page}&limit=${limit}`)
      if (res?.status === 200) {
        console.log(res.data)
        console.log(res.data)
        setConnectors(res.data.data)
        setPages(res?.data?.pagination?.totalPages)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllConnectors()
  }, [page])

  useEffect(() => {
    getAllConnectors()
  }, [])
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Total Connectors</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-4 rounded-1'>
            <thead>
              <tr className='text-start text-gray-500 bg-secondary-subtle fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-210px'>ChargeBoxId</th>
                <th className='min-w-210px'>Connector Id</th>
                <th className='min-w-210px'>Port Type</th>
                <th className='min-w-210px'>Power Output</th>
                <th className='min-w-210px'>Tariff Name</th>
                <th className='min-w-210px'>Tariff Gst</th>
                <th className='min-w-210px'>Charge Type</th>
                <th className='min-w-120px'>Status</th>
              </tr>
            </thead>
            <tbody>
              {connectors?.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item?.station?.stationId}</td>
                    <td>
                      <a
                        href='/metronic8/demo25/apps/ecommerce/catalog/edit-product.html'
                        className='text-gray-900 text-hover-primary'
                      >
                        {item?.connector_ID}
                      </a>
                    </td>
                    <td className='text-start'>{item.port_type} </td>
                    <td className='text-start'>{item.power_output}</td>
                    <td className='text-start dt-type-numeric'>{item?.Tariff?.name} </td>
                    <td className='text-start dt-type-numeric'>
                      {item.Tariff?.fixedPrice?.price}{' '}
                    </td>
                    <td className='text-start dt-type-numeric'>
                      {item.Tariff?.fixedPrice?.gst}{' '}
                    </td>
                    <td className='text-start dt-type-numeric'>{item?.station?.charge_type}</td>
                    {item?.status === 'Available' ? (
                      <td className=''>
                        <span className='badge text-bg-success text-white'>Available</span>
                      </td>
                    ) : item?.status === 'Unavailable' ? (
                      <td>
                        <span className='badge text-bg-danger text-white'>Unavailable</span>
                      </td>
                    ) : (
                      <td className='text-start dt-type-numeric'>
                        <span className='badge text-bg-warning text-white'>{item?.status}</span>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <PaginatedItems setPage={setPage} pages={pages} currentPage={page} />
        </div>
      </div>
    </div>
  )
}

export {TotalConnectors}
