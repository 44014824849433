import React, { useEffect, useState } from 'react'
import { getRequest, deleteRequest } from '../../../../app/pages/Helpers/Requests'
import { getDateFormat } from '../../../../functions'
import Pagination from '../../../../app/UI/Pagination'
import EditReportModal from '../../modals/EditReportModal'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
import toast from 'react-hot-toast'
import PaginatedItems from '../../../../app/UI/PaginatedItems'
import ReportImageModal from './ReportImageModal'

type Props = {
  className?: string
}

const Reports: React.FC<Props> = ({ className }) => {
  const [reportsData, setReportsData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pages, setPages] = useState<number>(1)
  const [selectedItem, setSelectedItem] = useState()
  const [selectedId, setSelectedId] = useState<any>()
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [ShowImageModal, setImageModal] = useState<boolean>(false)

  const fetchData = async () => {
    try {
      const response: any = await getRequest(`/bug-report?page=${page}&limit=8`)
      if (response.status === 200) {
        setReportsData(response.data.data)
        console.log(response.data)
        setPages(response.data.pagination.totalPages)
      } else {
        console.log('Unexpected data format:', response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/bug-report/${selectedId}`)
      console.log(res, res?.data)
      toast.success('Deleted SuccessFully')
      fetchData()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const CloseModal = () => {
    setImageModal(false)
    setSelectedImage(null)
  }

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'Submitted':
        return 'badge-light-white'
      case 'Bug Not Exists':
        return 'badge-light-warning'
      case 'Sent To Technical Team':
        return 'badge-light-info'
      case 'Resolved':
        return 'badge-light-success'
      case 'In Progress':
        return 'badge-light-primary'
      case 'Closed':
        return 'badge-light-danger'
      default:
        return 'badge-light'
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Bug Reports</span>
        </h3>
      </div>
      <div className='card-body py-2'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>Type of Platform</th>
                <th className='min-w-210px'>Type of Issue</th>
                <th className='min-w-210px'>Description</th>
                <th className='min-w-210px'>Date</th>
                <th className='min-w-210px'>Image</th>
                <th className='min-w-210px'>Status</th>
                <th className='min-w-210px text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length > 0 ? (
                reportsData.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item?.platform}</td>
                    <td>{item?.reportType}</td>
                    <td>{item?.description}</td>
                    <td>{getDateFormat(item?.createdAt)}</td>
                    <td>
                      <button
                        className='btn'
                        data-bs-toggle='modal'
                        data-bs-target='#Report_Modal'
                        onClick={() => {
                          setSelectedImage(item?.image)
                          setImageModal(true)
                        }}
                      >
                        <img
                          src={item?.image}
                          alt='ss'
                          style={{width: '40px', height: '40px'}}
                          sizes='cover'
                        />
                      </button>
                    </td>
                    <td>
                      <span className={`badge ${getStatusBadgeClass(item.status)}`}>
                        {item.status}
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                        data-bs-toggle='modal'
                        data-bs-target='#edit_report'
                        onClick={() => {
                          setSelectedItem(item)
                        }}
                      >
                        <i className='ki-duotone ki-pencil fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </a>
                      <button
                        onClick={() => {
                          setSelectedId(item?.id)
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#confirm_modal'
                      >
                        <i className='ki-duotone ki-trash fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className='text-center'>
                    No reports available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <Pagination page={page} setPage={setPage} pages={pages} /> */}
          <PaginatedItems currentPage={page} setPage={setPage} pages={pages} />

        </div>
      </div>
      <EditReportModal fetchData={fetchData} selectedItem={selectedItem} />
      <ConfirmModal handleSubmit={DeleteHandler} text='Do You Want To Delete this report?' />
      <ReportImageModal imageSrc={selectedImage} onClose={CloseModal} />
    </div>
  )
}

export default Reports
