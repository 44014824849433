
import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';




function PaginatedItems({ pages, currentPage, setPage }) {
    const isDisabled = pages === 0;

    return (
        <Pagination className='p-2'>
            <Pagination.First onClick={() => { setPage(1) }} disabled={isDisabled || currentPage === 1} />
            <Pagination.Prev onClick={() => { setPage(currentPage - 1) }} disabled={isDisabled || currentPage === 1} />

            {Array.from({ length: pages }, (_, index) => {

                const pageNumber = index + 1;

                if (pageNumber <= 2 || pageNumber > pages - 2) {
                    return (
                        <Pagination.Item key={index} active={pageNumber === currentPage} onClick={() => setPage(pageNumber)}>
                            {pageNumber}
                        </Pagination.Item>
                    );
                }
                if (pageNumber === currentPage - 1 || pageNumber === currentPage || pageNumber === currentPage + 1) {
                    return (
                        <Pagination.Item key={index} active={pageNumber === currentPage} onClick={() => setPage(pageNumber)} > {pageNumber}  </Pagination.Item>
                    );
                }
                if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                    return <Pagination.Ellipsis key={index} />;
                }

                return null;
            })}

            <Pagination.Next onClick={() => setPage(currentPage + 1)} disabled={isDisabled || currentPage === pages} />

            <Pagination.Last onClick={() => { setPage(pages) }} disabled={isDisabled || currentPage === pages} />
        </Pagination>
    );

}

export default PaginatedItems;