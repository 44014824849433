import React, {useEffect, useState} from 'react'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'
import {KTIcon} from '../../../helpers'
import AddConnectorType from '../../../../app/pages/Components/AddConnectorType'
import EditConnectorType from '../../../../app/pages/Components/EditConnectorType'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

export default function ConnectorTypesTable() {
  const [connectorType, setConnectorType] = useState([])
  const [modalId, setModalId] = useState('')

  const getConnectorTypes = async () => {
    try {
      const res = await getRequest(`/connector-type`)
      if (res && res.data) {
        console.log(res.data)
        setConnectorType(res.data.connectorTypes)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/connector-type/${modalId}`)
      if (res) {
        toast.success('Deleted SuccessFully ✅')
        getConnectorTypes()
      }
    } catch (error) {
      toast.error('Unable To Delete Data ❌')
    }
  }
  useEffect(() => {
    getConnectorTypes()
  }, [])

  return (
    <div>
      <div className={`card me-2 `}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>Connector Types</h3>
          <div className='card-toolbar d-flex gap-5'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
              data-bs-toggle='modal'
              data-bs-target='#add_connector'
            >
              <a href='#' className='text-dark fw-bold d-block fs-6'>
                <KTIcon iconName='plus' className='fs-3 btn btn-sm btn-success' />
              </a>
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-2'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Sl No.</th>
                  <th className='min-w-210px'>Name</th>
                  <th className='min-w-210px'>Power Type</th>
                  <th className='min-w-210px'>Image</th>
                  <th className='min-w-210px d-flex justify-content-end '>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {connectorType?.map((item: any, index: number) => {
                  return (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px me-5 '>
                                                <img alt='' className='rounded-circle' />
                                            </div> */}
                          <div className='d-flex justify-content-start flex-column'>
                            {item?.name}
                          </div>
                        </div>
                      </td>
                      <td>{item?.powerType}</td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5 '>
                            <img src={item?.image} alt='' className='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            {item?.brandName}{' '}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#edit_connector'
                            onClick={() => setModalId(item?.id)}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>

                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#confirm_modal'
                            onClick={() => setModalId(item?.id)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        <AddConnectorType getAllConnectorType={getConnectorTypes} />
        <EditConnectorType id={modalId} getAllConnectorType={getConnectorTypes} />
        <ConfirmModal
          text='Are you Sure. You want to delete the Connector ? '
          handleSubmit={DeleteHandler}
        />
      </div>
    </div>
  )
}
