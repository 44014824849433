import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../helpers'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import * as Yup from 'yup'
import {putRequest} from '../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

const EditReportModal = ({selectedItem, fetchData}: any) => {
  const [initialValues, setInitialValues] = useState({
    status: '',
  })

  const validationSchema = Yup.object({
    status: Yup.string().required('Status is required'),
  })

  const updateData = async (values, {resetForm}) => {
    try {
      const response: any = await putRequest(`/bug-report/${selectedItem?.id}`, {
        status: values.status,
      })
      if (response.status) {
        console.log(response)
        toast.success('Report Updated Successfully!')
        fetchData()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectedItem) {
      console.log(selectedItem.status)
      setInitialValues({
        status: selectedItem.status,
      })
    }
  }, [selectedItem])

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='edit_report'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-success'>
              <h5 className='modal-title text-white'>Edit Control</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={updateData}
                enableReinitialize={true}
              >
                {({isSubmitting}) => (
                  <Form>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Update Status</label>
                        <Field as='select' name='status' className='form-control'>
                          <option value='' hidden>
                            Select Status
                          </option>
                          <option value='Submitted'>Submitted</option>
                          <option value='Bug Not Exists'>Bug Not Exists</option>
                          <option value='Sent To Technical Team'>Sent To Technical Team</option>
                          <option value='Resolved'>Resolved</option>
                          <option value='In Progress'>In Progress</option>
                          <option value='Closed'>Closed</option>
                        </Field>
                        <ErrorMessage name='status' component='div' className='text-danger' />
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        disabled={isSubmitting}
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditReportModal
