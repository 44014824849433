import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import { postRequest } from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast';
const validationSchema = Yup.object().shape({
    location: Yup.string().required('Location is required'),
    retries: Yup.string().required('Retries is required'),
    retry_interval:Yup.string().required("Retry Interval is required"),
    start_date:Yup.mixed().required("Start date is required"),
    stop_date:Yup.mixed().required("Stop Date is required")
})


function GetDiagnostics({stationId}) {
  const [initialValues, setInitialValues] = useState({
    location:'',
    retries:"",
    retry_interval:"",
    start_date:"",
    stop_date:""
  })
  const handleSubmit =async (values, {resetForm}) => {
    try {
        console.log(values)
        const res=await postRequest('/ocpp/remote/get-diagnostics/'+stationId,values);
        if(res && res.data){
            toast.success(res.data.message);
        }
    } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='get_diagnostics'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Remote Start Transaction</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Location</label>
                        <Field placeholder="Location*" name='location' className='form-control' />
                        <ErrorMessage name='location' component={"div"} className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Retries</label>
                        <Field  name='retries' className='form-control' placeholder="Retries" />
                        <ErrorMessage name='retries' component={"div"} className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Retry Interval</label>
                        <Field  name='retry_interval' className='form-control' placeholder="Retry Interval" />
                        <ErrorMessage name='retry_interval' component={"div"} className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Start Date/Time</label>
                        <input type="datetime-local" name='start_date' className='form-control' placeholder="Start Date/Time" onChange={(e)=>{setFieldValue("start_date",new Date(e.target.value).toISOString())}} />
                        <ErrorMessage name='start_date' component={"div"} className='text-danger' />
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Stop Date/Time</label>
                        <input type="datetime-local" name='stop_date' className='form-control' placeholder="Stop Date/Time" onChange={(e)=>{setFieldValue("stop_date",new Date(e.target.value).toISOString())}}/>
                        <ErrorMessage name='stop_date' component={"div"} className='text-danger' />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetDiagnostics;
