import React from 'react'
import ChargerGeneralDetailsOne from './ChargerGeneralDetailsOne'

const ChargerGeneralDetail = ({errors, values, setFieldValue, touched}) => {
  return (
    <div>
      <ChargerGeneralDetailsOne
        errors={errors}
        values={values}
        setFieldValue={setFieldValue}
        touched={touched}
      />
    </div>
  )
}

export default ChargerGeneralDetail
