/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {MapContainer, TileLayer, Marker} from 'react-leaflet'
import L from 'leaflet'
import MapMaker from '../../../Markers/ZectMaker.png'
import {getRequest} from '../Helpers/Requests'
import AvailableIcon from '../../../Markers/ICON-04.png'
import FaultedIcon from '../../../Markers/ICON-05.png'
import ChargingIcon from '../../../Markers/ICON-03.png'
import PartialAvailable from '../../../Markers/ICON-06.png'

const markerIcon = new L.Icon({
  iconUrl: MapMaker,
  iconSize: [40, 50],
  iconAnchor: [17, 45],
})

const availableMarkerIcon=new L.Icon({
  iconUrl:AvailableIcon,
  iconSize:[40,50],
  iconAnchor:[17,45]
})
const partialAvailableMarkerIcon=new L.Icon({
  iconUrl:PartialAvailable,
  iconSize:[40,50],
  iconAnchor:[17,45]
})
const Faulted = new L.Icon({
  iconUrl: FaultedIcon,
  iconSize: [40, 50],
  iconAnchor: [17, 45],
})

const chargingSessionIcon=new L.Icon({
  iconUrl:ChargingIcon,
  iconSize:[40,50],
  iconAnchor:[17,45]
})


const InactiveMarkerIcon = new L.Icon({
  iconUrl: FaultedIcon,
  iconSize: [40, 50],
  iconAnchor: [17, 45],
})

const Home = () => {
  const [isVisible, setIsVisible] = useState({lat: 14.707891, lng: 77.607965})
  const [locations, setLocations] = useState<any>([])
  const [stationStatus,setStationStatus]=useState<any[]>([])
  const Zoom_Level = 5

  const getAllLocations = async () => {
    try {
      const res = await getRequest(`/all/locations`)
      console.log(res?.data.data.locations)
      if (res && res.data) {
        setLocations(res.data.data.locations)
        const stations=res.data.data.locations.map((item)=>{
          return item.station
        })

        stations.map((station)=>{
          let stationItem={
            id:'',
            status:''
          }
          console.log(station)
          station.chargers.connectors.map((connector)=>{
            if(connector.status==="Unavailable"||connector.status==="Faulted"||connector.status==="SuspendedEv"){
              stationItem.id=station.id;
              stationItem.status="Unavailable"
            }else if(connector.status==="Charging"){
              stationItem.id=station.id;
              stationItem.status="Charging"
            }else if(connector.status==="Available"){
              stationItem.id=station.id;
              stationItem.status="Available"
            }
          })
          setStationStatus([...stationStatus,stationItem])
        })
      }
    } catch (error: any) {
      console.log(error)
    }
  }
  useEffect(()=>{
    console.log(stationStatus)
  },[stationStatus])

  useEffect(() => {
    getAllLocations()
  }, [])

  return (
    <div style={{width: '100%', marginTop: '-70px'}}>
      <div style={{overflow: 'hidden'}}>
        <MapContainer
          style={{height: '84vh', width: '100%', overflow: 'hidden'}}
          center={isVisible}
          zoom={Zoom_Level}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution=''
            url='https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=1lr4p1ZPHGfGrNtJ34uL'
          />
          <div>
            {locations.map((position: any, index: any) => (
              <Marker
                key={index}
                position={[position.latitude, position.longitude]}
                icon={position?.station?.status === 'Active' ? markerIcon : markerIcon}
              ></Marker>
            ))}
          </div>
        </MapContainer>
      </div>
    </div>
  )
}

export default Home
