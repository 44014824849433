import React, {useEffect, useState} from 'react'
import EditControll from '../../modals/AdminControllModal'
import {getRequest} from '../../../../app/pages/Helpers/Requests'

type Props = {
  className?: string
}

const AdminControll: React.FC<Props> = ({className}) => {
  const [options, setOptions] = useState([])
  const [selectedItem, setSelectedItem] = useState(undefined)
  const getAdminOPtions = async () => {
    //Get admin options
    try {
      const res = await getRequest('/admin-option')
      if (res && res.status === 200) {
        console.log(res.data)
        setOptions(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const openEditModal = (item: any) => {
    setSelectedItem(item)
  }

  useEffect(() => {
    getAdminOPtions()
  }, [])
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Admin Options</span>
        </h3>
      </div>
      <div className='card-body py-2'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Option</th>
                <th className='min-w-140px'>Value</th>
                <th className='min-w-140px'>Platform</th>
                <th className='min-w-140px'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {options.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{item?.key}</td>
                    <td>{item?.value}</td>
                    <td>{item?.platform}</td>
                    <td>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                        data-bs-toggle='modal'
                        data-bs-target='#edit_controll'
                        onClick={() => {
                          openEditModal(item)
                        }}
                      >
                        <i className='ki-duotone ki-pencil fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <EditControll selectedItem={selectedItem} fetchData={getAdminOPtions} />
    </div>
  )
}

export default AdminControll
