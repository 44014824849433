import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Formik, ErrorMessage, Field, Form } from 'formik'
import toast from 'react-hot-toast'
import { postRequest, putRequest } from '../Helpers/Requests'
import * as Yup from 'yup'

export default function EditUserStationPrice({ UserID, StationID, allSations }) {
  const PriceValidation = Yup.object().shape({
    price: Yup.number().required('Price is required'),
  })

  console.log(StationID)
  const handleSubmit = async (values, { resetForm }) => {
    const data = { ...values, userId: parseInt(UserID), stationId: StationID }
    try {
      const res = await postRequest('/ev/users/station/price', data)
      if (res) {
        toast.success('Price updated successfully')
        allSations()
        resetForm()
      }
    } catch (error) {
      console.error(error)
      // toast.error(error.response?.data.message)
    }
  }
  return (
    <div className='modal fade' tabIndex={-1} id='edit_station_user_price'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>User Price</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={{ price: '' }}
              enableReinitialize={true}
              validationSchema={PriceValidation}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className='mb-10'>
                    <label className='form-label'>Price</label>
                    <Field
                      type='number'
                      className='form-control'
                      name='price'
                      placeholder='Enter price'
                    />

                    <ErrorMessage name='price' component='div' className='text-danger' />
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
