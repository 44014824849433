/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link, useParams} from 'react-router-dom'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import TariffEditModal from './TariffEditModal'
import {useAuth} from '../../../../app/modules/auth'
import toast from 'react-hot-toast'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'

type Props = {
  className: string
}

const TariffTableCpo: React.FC<Props> = ({className}) => {
  const {pointId} = useParams()
  const [tariffs, setTariffs] = useState([])
  const [tariffId, setTariffId] = useState()
  const [tariff, setTariff] = useState<any>()
  const {currentUser} = useAuth()
  const [requests, setRequests] = useState([])
  const [requestId, setRequestId] = useState()
  const [refinedData, setRefinedData] = useState([])

  const getTariffByCPOId = async () => {
    try {
      const res = await getRequest('/tariff/operator/' + pointId)
      console.log(res)
      if (res?.status === 200) {
        console.log(res.data.data)
        setTariffs(res.data.data)
        const result = res.data.data.flatMap((tariff) =>
          tariff.connectors.map((connector) => ({
            id:tariff.id,
            stationId: connector.station.stationId,
            tariffName: tariff.name,
            fixedPrice: tariff.fixedPrice.price,
            priceType: tariff.priceType,
            tariffBase: tariff.fixedPrice.time,
            connectorId:connector.connector_ID,
            gst:tariff.fixedPrice.gst
          }))
        )
        setRefinedData(result);

      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllTariffChangeRequestsSent = async () => {
    try {
      const res = await getRequest('/tariff-change/cpo/' + pointId)
      if (res?.status === 200) {
        console.log(res.data.data)
        setRequests(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const withDrawRequest = async (id) => {
    try {
      const res = await deleteRequest('/tariff-change/withdraw/' + id)
      if (res?.status === 200) {
        toast.success('Tariff Change request successfully withdrawn')
        getAllTariffChangeRequestsSent()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTariffByCPOId()
    getAllTariffChangeRequestsSent()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>Tariff Name</th>
                <th className='min-w-110px'>Station Id</th>
                <th className='min-w-210px'>Connector</th>
                <th className='min-w-210px'>Price Type</th>
                <th className='min-w-210px'>Tariff Base</th>
                <th className='min-w-210px'>Price</th>
                <th className='min-w-210px'>GST</th>
                {currentUser?.group === '2' && <th className='min-w-210px text-end'>Actions</th>}
              </tr>
            </thead>

            <tbody>
              {refinedData?.map((item: any, index: any) => {

                return (
                  <tr className='fs-5 fw-bold' key={index}>
                    <td>{item?.tariffName}</td>
                    <td>{item?.stationId}</td>
                    <td>{item?.connectorId}</td>
                    <td>{item?.priceType}</td>
                    <td>{item?.tariffBase}</td>
                    <td>₹{item?.fixedPrice}</td>
                    <td>{item?.gst}</td>
                    {currentUser?.group === '2' && (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            data-bs-toggle='modal'
                            data-bs-target='#tariff_edit'
                            className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                            onClick={() => {
                              
                              setTariffId(item?.id)
                              setTariff((prev) => {
                                return item
                              })
                            }}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <h3 className='text-danger'>Tariff Change Request's</h3>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-210px'>Tariff Name</th>
                <th className='min-w-210px'>Price Type</th>
                <th className='min-w-210px'>Old Price</th>
                <th className='min-w-210px'>Updated Price</th>
                <th className='min-w-210px'>Status</th>
                {currentUser?.group === '2' && <th className='min-w-210px text-end'>Withdraw</th>}
              </tr>
            </thead>

            <tbody>
              {requests?.map((item: any, index: any) => {
                return (
                  <tr className='fs-5 fw-bold' key={index}>
                    <td>{item?.tariffName}</td>
                    <td>{item?.priceType}</td>
                    <td>{item?.oldPrice}</td>
                    <td>₹{item?.updatedPrice}</td>
                    <td>
                      {item?.status === 0 ? (
                        <span className='badge badge-warning'>Pending</span>
                      ) : item?.status === 5 ? (
                        <span className='badge badge-danger'>Withdrawn</span>
                      ) : item?.status === 1 ? (
                        <span className='badge badge-success'>Accepted</span>
                      ) : item?.status === 2 ? (
                        <span className='badge badge-danger'>Rejected</span>
                      ) : (
                        <span className='badge badge-info'>Other</span>
                      )}
                    </td>
                    {currentUser?.group === '2' && (
                      <td>
                        {item?.status === 0 && (
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                              onClick={() => {
                                withDrawRequest(item?.id)
                              }}
                            >
                              <KTIcon
                                iconName={`${item?.status === 0 ? 'trash' : 'abstract-11'}`}
                                className='fs-3'
                              />
                            </a>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <TariffEditModal
        tariffId={tariffId}
        item={tariffs.find((item: any) => item?.id === tariffId)}
      />
    </div>
  )
}

export {TariffTableCpo}
