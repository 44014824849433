import React from 'react'
import {KTIcon, KTSVG} from '../../../helpers'
import {Form, Formik} from 'formik'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

function TariffEditModal({tariffId, item}) {
  console.log(tariffId, 'tariffId')
  const initialValues = {
    updatedPrice: null,
  }
  const handleSubmit = async (values, {resetForm}) => {
    try {
      if(!values.updatedPrice){
        toast.error("Please Provide the updated Price")
        return;
      }
      const res = await postRequest('/tariff-change/', {
        tariffId: tariffId,
        oldPrice: item.fixedPrice.price,
        updatedPrice: values.updatedPrice,
        tariffName: item.name,
      })
      if (res?.status === 200) {
        toast.success('Successfully posted the request for Tariff Change')
        resetForm();
      }
      console.log(values, 'Form submission values')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      className='modal fade'
      id='tariff_edit'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id=''>
              Request for a Tariff Change
            </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({setFieldValue}) => (
                <Form>
                  <div className='row mb-5'>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label'>Tariff Name</label>
                      <input
                        className='form-control'
                        placeholder='Name of the Tariff'
                        value={item?.name}
                        disabled={true}
                      />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <label className='form-label required'>Value</label>
                      <input
                        name='updatedPrice'
                        onChange={(e:any)=>{setFieldValue("updatedPrice",e.target.value)}}
                        className='form-control'
                        placeholder='Updated Price of the Tariff'
                      />
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light-secondary font-weight-bold'
                      data-bs-dismiss='modal'
                    >
                      Close
                    </button>
                    <button type='submit' data-bs-dismiss="modal" className='btn btn-success font-weight-bold'>
                      Save changes
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TariffEditModal
