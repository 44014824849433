import React, { useEffect, useState } from 'react'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import toast from 'react-hot-toast'
import { KTSVG } from '../../../_metronic/helpers'
import { getRequest, postRequest } from '../Helpers/Requests'
import Select from 'react-select'
import { CarValidation } from '../js/CarValidation'

export default function AddCar({ getAllCar, carbrands }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
        brandId: '',
        carType: '',
        image: null,
        connectorType: [],
    })


    const [connectorType, setConnectorType] = useState([])
    const [modal, setModal] = useState(false);

    const CloseModal = () => {
        setModal(false);
    }


    const getConnectorTypes = async () => {
        try {
            const res = await getRequest(`/connector-type`)
            if (res && res.data) {
                console.log(res.data)
                setConnectorType(res.data?.connectorTypes)
            }
        } catch (error) {
            console.log(error)
        }

    }


    const handleSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append("image", values.image || '');
        formData.append("name", values.name);
        formData.append("brandId", values.brandId);
        formData.append("carType", values.carType);

        values.connectorType.forEach((item: any) => {
            console.log(item);
            formData.append("connectorType", item);
        });

        try {
            console.log(values);
            const res = await postRequest('/car', formData)
            if (res && res.data) {
                toast.success(res.data.message);
                getAllCar()
                resetForm();
            }
        } catch (error: any) {
            console.log(error)
            toast.error(error.response?.data?.message || 'An error occurred')
        }
    }

    useEffect(() => {
        getConnectorTypes();
    }, [])


    return (
        <div className='modal fade' tabIndex={-1} id='add_car'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header bg-success'>
                        <h5 className='modal-title text-white'>Add Car</h5>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-success ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'

                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>
                    <div className='modal-body'>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={CarValidation}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Car Name</label>
                                            <Field placeholder='Car Name' name='name' className='form-control requi' type="text" />
                                            <ErrorMessage name='name' component='div' className='text-danger' />
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Car Type</label>
                                            {/* <Field placeholder='Car Type' name='carType' className='form-control' /> */}
                                            <Field as='select' name='carType' className='form-control'>
                                                <option value=''>Select Car Type</option>
                                                <option value='SUV'>SUV</option>
                                                <option value='MUV'>MUV</option>
                                                <option value='HatchBack'>HatchBack</option>
                                                <option value='Sedan'>Sedan</option>
                                                <option value='Coupes'>Coupes</option>
                                                <option value='Convertible'>Convertible</option>
                                            </Field>
                                            <ErrorMessage name='carType' component='div' className='text-danger' />
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Car Brand</label>
                                            <Field as='select' name='brandId' className='form-control'>
                                                <option value=''>Select Car Brand</option>
                                                {carbrands?.map((item: any) => {

                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.brandName}
                                                        </option>
                                                    )
                                                })}
                                            </Field>
                                            <ErrorMessage name='brandId' component='div' className='text-danger' />
                                        </div>

                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Port Type</label>

                                            <Select
                                                className='react-select-styled react-select-solid'
                                                classNamePrefix='react-select'
                                                placeholder='Select an option'
                                                isMulti
                                                name='connectorType'

                                                options={connectorType?.map((item: any) => ({
                                                    value: item.id,
                                                    label: item.name,
                                                    key: item.id,

                                                }))}
                                                onChange={(selectedOption: any) => {
                                                    const testvalues = selectedOption.map((item: any) => item.value)
                                                    setFieldValue('connectorType', testvalues)
                                                }}
                                            />
                                            <ErrorMessage name='connectorType' component='div' className='text-danger' />
                                        </div>

                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Car image</label>
                                            <input placeholder='Image' type='file' className='form-control'
                                                onChange={(event: any) => {
                                                    setFieldValue('image', event.target.files[0])
                                                }} />
                                            <ErrorMessage name='image' component='div' className='text-danger' />
                                        </div>

                                    </div>
                                    <div className='modal-footer mt-3'>
                                        <button type="reset" className='btn btn-light-danger' data-bs-dismiss='modal'>
                                            Cancel
                                        </button>
                                        <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}