/* eslint-disable */

import React, {useEffect, useState} from 'react'
import GeneralDetail from './GeneralDetail'
import Amenities from './Amenities'
import {Formik, Form, FieldArray} from 'formik'
import {newChargeStationValidation} from '../js/NewChargeStationValidation'
import toast, {Toaster} from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {ChargeStationInitialValues} from '../Helpers/AllInitialValues'
import {getRequest, postRequest} from '../Helpers/Requests'
import api_instance from '../../API/Api_Instance'
import * as Yup from 'yup'
import ChargerGeneralDetail from './ChargerGeneralDetail'
import {Field} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import ConnectorDetails from './ConnectorDetails'
const stateOptions = [
  {value: 'AN', label: 'Andaman and Nicobar Islands'},
  {value: 'AP', label: 'Andhra Pradesh'},
  {value: 'AR', label: 'Arunachal Pradesh'},
  {value: 'AS', label: 'Assam'},
  {value: 'BR', label: 'Bihar'},
  {value: 'CH', label: 'Chandigarh'},
  {value: 'CT', label: 'Chhattisgarh'},
  {value: 'DL', label: 'Delhi'},
  {value: 'GA', label: 'Goa'},
  {value: 'GJ', label: 'Gujarat'},
  {value: 'HP', label: 'Himachal Pradesh'},
  {value: 'HR', label: 'Haryana'},
  {value: 'JH', label: 'Jharkhand'},
  {value: 'JK', label: 'Jammu and Kashmir'},
  {value: 'KA', label: 'Karnataka'},
  {value: 'KL', label: 'Kerala'},
  {value: 'LA', label: 'Ladakh'},
  {value: 'LD', label: 'Lakshadweep'},
  {value: 'MH', label: 'Maharashtra'},
  {value: 'MN', label: 'Manipur'},
  {value: 'ML', label: 'Meghalaya'},
  {value: 'MZ', label: 'Mizoram'},
  {value: 'NL', label: 'Nagaland'},
  {value: 'OD', label: 'Odisha'},
  {value: 'PB', label: 'Punjab'},
  {value: 'PY', label: 'Puducherry'},
  {value: 'RJ', label: 'Rajasthan'},
  {value: 'SK', label: 'Sikkim'},
  {value: 'TN', label: 'Tamil Nadu'},
  {value: 'TS', label: 'Telangana'},
  {value: 'TR', label: 'Tripura'},
  {value: 'UP', label: 'Uttar Pradesh'},
  {value: 'UK', label: 'Uttarakhand'},
  {value: 'WB', label: 'West Bengal'},
]

const ChargeStationValidationSchema = Yup.object().shape({
  hubId: Yup.string().required('Station ID is required'),
  name: Yup.string().required('Charger name is required'),
  stationId: Yup.string().required('Station ID is required'),
  communication: Yup.string().required('Communication type is required'),
  charge_type: Yup.string().required('Charge type is required'),
  status: Yup.string().required('Status is required'),
  state: Yup.string().required('State is required'),
  connectors: Yup.array()
    .of(
      Yup.object().shape({
        connector_ID: Yup.number().required('Connector ID is required'),
        port_type: Yup.string().required('Port type is required'),
        power_output: Yup.string().required('Power output is required'),
        tariffId: Yup.string().required('Tariff ID is required'),
      })
    )
    .required('At least one connector is required')
    .min(1, 'At least one connector is required'),
})

const EditCharger = () => {
  const {chargerId} = useParams()
  const [openAmenities, setOpenAmenities] = useState(false)
  const navigate = useNavigate()
  const [charger, setCharger] = useState<any>({})
  const [selectedState, setSelectedState] = useState<any>('')
  const [type, setType] = useState<any>('DC')
  const [tariffs, setTariffs] = useState<any>([])

  const [connectorTypes, setConnectorTypes] = useState([])
  const getConnectorTypes = async () => {
    try {
      console.log(charger.charge_type)
      const res = await getRequest(
        `/connector-type/?type=${charger.charge_type}&attributes=id,name`
      )
      if (res && res.data) {
        console.log(res.data)
        setConnectorTypes(res.data.connectorTypes)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (charger?.hubId !== '') {
      getTariffs()
    }
  }, [charger?.hubId])

  useEffect(() => {
    getConnectorTypes()
  }, [charger?.charge_type])

  const handleSubmit = async (values: any, {resetForm}) => {
    try {
      const res = await api_instance.put(`/charger/${chargerId}`, {...values, state: selectedState})
      if (res && res.data) {
        toast.success('Charge Station Created Successfully')
        setTimeout(() => {
          resetForm()
          navigate(-1)
        }, 1000)
      }
    } catch (error) {
      toast.error('Invalid Data Check Data ')
      console.error('Error:', error)
    }
  }

  const getChargerDetails = async () => {
    try {
      const res = await getRequest('/charger/' + chargerId)
      if (res?.status === 200) {
        setCharger(res.data.data)
        console.log(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTariffs = async () => {
    try {
      const res = await getRequest(`/tariff/hub/${charger.hubId}`)

      if (res && res.data) {
        setTariffs(res.data.data)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getChargerDetails()
  }, [])

  return (
    <div className='mt-0'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>ADD CHARGE STATION</h3>
        </div>
        <div className='card-body mt-5'>
          <Formik initialValues={charger} enableReinitialize={true} onSubmit={handleSubmit}>
            {({errors, values, setFieldValue, touched}: any) => (
              <Form>
                <div className='mt-15'>
                  <div className={`${openAmenities ? 'd-none' : 'd-block'}`}>
                    <div className='card card-custom'>
                      <div className='card-header flex-nowrap border-0 pt-9'>
                        <div className='card-title m-0'>
                          <div className='symbol symbol-70px w-70px bg-light me-5'>
                            <img
                              src={toAbsoluteUrl('/media/svg/card-logos/car-01.svg')}
                              alt='Metronic'
                              className='p-3'
                            />
                          </div>

                          <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                            Charger Details
                          </a>
                        </div>

                        <div className='card-toolbar m-0'></div>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='row'>
                              <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    type='text'
                                    className='form-control'
                                    aria-label='Select example'
                                    name='hubId'
                                    disabled
                                    value={charger?.hub?.name}
                                  ></Field>
                                  <span className='text-danger fs-5'>
                                    {touched.hubId && errors.hubId && errors.hubId}
                                  </span>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    type='text'
                                    className='form-control'
                                    aria-label='Select example'
                                    placeholder='Name of Station'
                                    name='name'
                                  />
                                  <span className='text-danger fs-5'>
                                    {touched.name && errors.name && errors.name}
                                  </span>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    as='select'
                                    className='form-select'
                                    aria-label='Select example'
                                    name='communication'
                                  >
                                    <option value='Communication Medium'>
                                      Communication Medium
                                    </option>
                                    <option value='Wifi'>Wifi</option>
                                    <option value='3G/4G'>3G/4G</option>
                                    <option value='5G'>5G</option>
                                  </Field>
                                  <span className='text-danger fs-5'>
                                    {touched.communication &&
                                      errors.communication &&
                                      errors.communication}
                                  </span>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    as='select'
                                    className='form-select'
                                    aria-label='Select example'
                                    name='charge_type'
                                    disabled={true}
                                    onChange={(e: any) => {
                                      setFieldValue('charge_type', e.target.value)
                                      setType(e.target.value)
                                    }}
                                  >
                                    <option>Charge Type</option>
                                    <option value='AC'>AC</option>
                                    <option value='DC'>DC</option>
                                  </Field>
                                  <span className='text-danger fs-5'>
                                    {touched.charge_type &&
                                      errors.charge_type &&
                                      errors.charge_type}
                                  </span>
                                </div>
                              </div>
                              {/* <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    as='select'
                                    className='form-select'
                                    aria-label='Select example'
                                    name='state'
                                    disabled={true}
                                    value={selectedState}
                                    onChange={(e: any) => {
                                      setFieldValue('state', e.target.value)
                                    }}
                                  >
                                    <option value=''>--Select a state--</option>
                                    {stateOptions?.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </Field>
                                  <span className='text-danger fs-5'>
                                    {touched.state && errors.state && errors.state}
                                  </span>
                                </div>
                              </div> */}

                              <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    type='text'
                                    className='form-control'
                                    placeholder='StationID'
                                    name='stationId'
                                    disabled={true}
                                  />
                                  <span className='text-danger fs-5'>
                                    {touched.stationId && errors.stationId && errors.stationId}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col-md-4'>
                                <div className='mb-10'>
                                  <Field
                                    as='select'
                                    className='form-select'
                                    aria-label='Select example'
                                    name='status'
                                  >
                                    <option value=''>Status</option>
                                    <option value='Active'>Active</option>
                                    <option value='InActive'>InActive</option>
                                  </Field>
                                  <span className='text-danger fs-5'>
                                    {touched.status && errors.status && errors.status}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div>
                                <div className='card card-custom mt-15 mb-10'>
                                  <div className='card-header flex-nowrap border-0 pt-9'>
                                    <div className='card-title m-0'>
                                      <div className='symbol symbol-55px w-55px bg-light me-5'>
                                        <img
                                          src={toAbsoluteUrl('/media/svg/card-logos/connector.svg')}
                                          alt='connector'
                                          className='p-3'
                                        />
                                      </div>

                                      <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                                        Connector Details
                                      </a>
                                    </div>

                                    <div className='card-toolbar m-0'></div>
                                  </div>
                                  <div className='card-body'>
                                    <FieldArray
                                      name='connectors'
                                      render={(arrayHelpers) => (
                                        <div>
                                          {values?.connectors?.map((connector, i) => (
                                            <div key={i}>
                                              <div className='row'>
                                                <div className='col-md-12'>
                                                  <div className='row'>
                                                    <div className='col-md-4'>
                                                      <div className='mb-10'>
                                                        <Field
                                                          className='form-control'
                                                          aria-label='Select example'
                                                          name={`connectors[${i}].connector_ID`}
                                                          placeholder='Connector ID'
                                                          disabled={true}

                                                          // value={i+1}
                                                        ></Field>
                                                        <span className='text-danger fs-5'>
                                                          {/* {touched.connector_ID && errors.connector_ID && errors.connector_ID} */}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                      <div className='mb-10'>
                                                        <Field
                                                          as='select'
                                                          className='form-select'
                                                          aria-label='Select example'
                                                          name={`connectors[${i}].port_type`}
                                                        >
                                                          <option value=''>Select Port Type</option>
                                                          {connectorTypes.map(
                                                            (item: any, index: number) => {
                                                              return (
                                                                <option key={index} value={item.id}>
                                                                  {item.name}
                                                                </option>
                                                              )
                                                            }
                                                          )}
                                                        </Field>

                                                        <span className='text-danger fs-5'>
                                                          {/* {errors.port_type && errors.port_type} */}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                      <div className='mb-10'>
                                                        <Field
                                                          type='text'
                                                          className='form-control'
                                                          placeholder='Power Output (Kw)'
                                                          name={`connectors[${i}].power_output`}
                                                        />
                                                        {/* <span className='text-danger fs-5'>
                                {errors.power_output && errors.power_output}
                              </span> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='row'>
                                                    <div className='col-md-4'>
                                                      <div className='mb-10'>
                                                        <Field
                                                          as='select'
                                                          className='form-select'
                                                          aria-label='Select example'
                                                          name={`connectors[${i}].tariffId`}
                                                        >
                                                          <option>Select Tariff</option>
                                                          {tariffs.map((e, i) => {
                                                            return (
                                                              <option key={i} value={e.id}>
                                                                {e.name}
                                                              </option>
                                                            )
                                                          })}
                                                        </Field>
                                                        {/* <span className='text-danger fs-5'>
                                {errors.tariffId && errors.tariffId}
                              </span> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                          <div className='d-flex justify-content-end'>
                                            <button
                                              type='button'
                                              className='btn btn-light-success'
                                              onClick={() => {
                                                if (values.connectors.length < 4) {
                                                  arrayHelpers.push({
                                                    connector_ID: values.connectors.length + 1,
                                                    port_type: '',
                                                    power_output: '',
                                                    tariffId: '',
                                                  })
                                                }
                                              }}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  {/*TABEL: ONE: Ends: HERE */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end mt-10'>
                      <div className='mb-10 me-5'>
                        <button type='submit' className='btn btn-light-success'>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EditCharger
