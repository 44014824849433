import axios from 'axios'
const baseurl = process.env.REACT_APP_API_URL

const getToken = (): string | null => {
  const storedToken = localStorage.getItem('kt-auth-react-v')
  if (storedToken) {
    const {accessToken} = JSON.parse(storedToken)
    return accessToken
  }
  return null
}

const api_instance = axios.create({
  baseURL: baseurl,
  withCredentials: true,
})

export const refreshToken = async () => {
  try {
    const res = await api_instance.get('/auth/refresh')
    if (res) {
      return res
    }
  } catch (error) {
    console.error('Error refreshing token:', error)
  }
  return null
}

api_instance.interceptors.request.use(
  async (config) => {
    const accessToken = getToken()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api_instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const res = await refreshToken()
      if (res && res.data) {
        return api_instance(originalRequest)
      }
    }
    if (error.response?.status === 401 && originalRequest._retry) {
      if (!window.location.pathname.includes('auth')) {
        window.location.href = '/auth'
      }
    }
    return Promise.reject(error)
  }
)

export default api_instance
