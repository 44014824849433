import React, {useEffect, useState} from 'react'
import {FinancialSettlementsTable} from '../../../_metronic/partials/widgets/tables/FinancialSettlementsTable'
import {KTIcon} from '../../../_metronic/helpers'
import AddExpenseModal from './AddExpenseModal'
import SettlementModal from './SettlementModal'
import {getRequest} from '../Helpers/Requests'
import {useAuth} from '../../modules/auth'
import {StatisticsWidget5} from '../../../_metronic/partials/widgets'

const months = [
  'January',
  'Febraury',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const FinancialSettlements = ({stations}) => {
  const {currentUser} = useAuth()
  const [stationId, setStationId] = useState('')
  const [expenses, setExpenses] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [settlementMonth, setSettlementMonth] = useState(new Date().getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [settlements, setSettlements] = useState([])
  const [report, setReport] = useState({
    pendingAmounts: null,
    totalTransactionAmount: null,
    expensesTotal: null,
    total: 0,
    totalSettled:null,
  })

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value

    setStationId(selectedValue)
  }

  const handleMonthChange = (value, settleValue) => {
    setSelectedMonth(value)
    setSettlementMonth(settleValue)
  }

  const getAllExpensesByStaionId = async () => {
    try {
      const res = await getRequest(
        `/expenses/station/${stationId}?month=${selectedMonth}&year=${selectedYear}`
      )
      if (res?.status === 200) {
        console.log(res.data.data)
        setExpenses(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllSettlementsByMonth = async () => {
    try {
      const res = await getRequest(
        `/settlements/station-month-wise/${stationId}?month=${settlementMonth}&year=${selectedYear}`
      )
      console.log(res)
      if (res?.status === 200) {
        console.log(res.data.data)
        setSettlements(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getReportByMonth = async () => {
    try {
      const res = await getRequest(
        `/settlements/report/${stationId}?month=${selectedMonth}&year=${selectedYear}`
      )
      if (res?.status === 200) {
        console.log(res.data.data)
        setReport({
          ...res.data.data,
          total: (
            parseFloat(res.data.data.totalTransactionAmount) +
            res.data.data.pendingAmounts -
            res.data.data.expensesTotal
          ).toFixed(2),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stationId !== '' && stationId !== null && stationId !== undefined) {
      getAllExpensesByStaionId()
      getReportByMonth()
    }
  }, [stationId, selectedMonth])

  useEffect(() => {
    if (stationId !== '' && stationId !== null && stationId !== undefined) {
      getAllSettlementsByMonth()
    }
  }, [stationId, settlementMonth])

  useEffect(() => {
    console.log(report)
  }, [report])


  useEffect(()=>{
    setStationId(stations[0]?.id)
  },[stations])


  return (
    <>
      <div>
        <div className='row mb-5 justify-content-between'>
          <div className='col-md-4 d-flex justify-content-end'>
            <select className='form-select' aria-label='Select example' value={stationId} onChange={handleChange}>
              <option>Select Station</option>
              {stations?.map((item: any, index: any) => {
                return (
                  <option key={index} value={`${item?.id}`}>
                    {`${item?.name} (${item?.hubId})`}{' '}
                  </option>
                )
              })}
            </select>
          </div>
          {stationId && currentUser?.group === '1' && (
            <>
              {/* <div className='col-md-6 d-flex justify-content-end'>
                <button
                  className='btn btn-light-success'
                  data-bs-toggle='modal'
                  data-bs-target='#settle_amount'
                >
                  <KTIcon iconName='abstract-14' className='fs-2' />
                  Settlement
                </button>
              </div> */}
              <div className='col-md-2 d-flex justify-content-end'>
                <button
                  className='btn btn-light-danger'
                  data-bs-toggle='modal'
                  data-bs-target='#add_expense'
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Expense
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      

      {stationId && (
        <div className='text-center mb-5 p-4 pt-0'>
          <div className='row'>
            <div className='col-xl-3'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='cheque'
                color='dark'
                iconColor='white'
                title={`₹ ${report?.totalTransactionAmount || 0}`}
                titleColor='white'
                description={`Transactions Amount ${months[selectedMonth-1]}`}
                descriptionColor='white'
              />
            </div>
            <div className='col-xl-3'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='cheque'
                color='secondary'
                iconColor='black'
                title={`₹ ${report?.expensesTotal || 0}`}
                titleColor='black'
                description={`Expenses of ${months[selectedMonth-1]}`}
                descriptionColor='black'
              />
            </div>
            <div className='col-xl-3'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='cheque'
                color='danger'
                iconColor='white'
                title={`₹ ${report?.pendingAmounts || 0}`}
                titleColor='white'
                description={`Pending Amount's ${months[selectedMonth-1]}`}
                descriptionColor='white'
              />
            </div>
            <div className='col-xl-3'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='cheque'
                color='success'
                iconColor='white'
                title={`₹ ${report?.totalSettled || 0}`}
                titleColor='white'
                description={`Total Settlements till Date`}
                descriptionColor='white'
              />
            </div>
            
          </div>
        </div>
      )}

      <FinancialSettlementsTable
        expenses={expenses}
        settlements={settlements}
        changeMonth={handleMonthChange}
        className='card-xxl-stretch mb-5 mb-xl-8'
        getSettlements={getAllSettlementsByMonth}
      />
      <AddExpenseModal stationRef={stationId} />
      <SettlementModal settlementId={1} getSettlements={getAllSettlementsByMonth} />
    </>
  )
}

export default FinancialSettlements
