/* eslint-disable */

import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { deleteRequest, getRequest } from '../Helpers/Requests'
import toast from 'react-hot-toast'
import ConfirmModal from '../../modules/Confirmation/ConfirmModal'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'

const HubDeviceSpecification = () => {
  let { pointId } = useParams()
  const [DeviceSpectifications, setDeviceSpectifications] = useState<any>(null)
  const [specification, setSpecification] = useState<any>()
  const [selectedId, setSelectedId] = useState()

  const getStationById = async () => {
    try {
      const res = await getRequest(`/hub/${pointId}`)
      if (res && res.data) {
        console.log(res.data.data.hub)
        setDeviceSpectifications(res.data.data.hub)
      }
    } catch (error: any) {
      console.log(error.messsage)
    }
  }

  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/charger/${selectedId}`)

      if (res && res.data) {
        toast.success('Deleted SuccessFully')
        getStationById()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  const getDeviceSpecification = async () => {
    try {
      const res = await getRequest('/device-specification/' + DeviceSpectifications.stationId)
      if (res?.status === 200) {
        setSpecification(res.data.data)
        console.log(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getStationById()
  }, [])

  useEffect(() => {
    if (
      DeviceSpectifications &&
      DeviceSpectifications.stationId !== null &&
      DeviceSpectifications.stationId !== undefined &&
      DeviceSpectifications.stationId !== ''
    ) {
      getDeviceSpecification()
    }
  }, [DeviceSpectifications])

  return (
    <div>
      {/* ChargeStation:Details:: Starts */}
      <div className='card mb-5 mt-15 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body p-9'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    DeviceSpectifications?.image
                      ? DeviceSpectifications?.image
                      : '/media/avatars/blank.png'
                  }
                  alt='image'
                />
                <div
                  className={`position-absolute translate-middle bottom-0 start-100 mb-6 ${DeviceSpectifications?.type === 'Public' ? 'bg-success' : 'bg-danger'
                    } rounded-circle border border-4 border-body h-20px w-20px`}
                />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                      {DeviceSpectifications?.stationName}
                    </a>
                    <a href='#'>
                      <i className='ki-outline ki-verify fs-1 text-primary' />
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'
                    >
                      <i className='ki-outline ki-profile-circle fs-4 me-1' />
                      {DeviceSpectifications?.operator?.OperatorName}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'
                    >
                      <i className='ki-outline ki-geolocation fs-4 me-1' />{' '}
                      {DeviceSpectifications?.location?.city}
                    </a>
                    {/* <a
                          href='#'
                          className='d-flex align-items-center text-gray-500 text-hover-primary mb-2'
                        >
                          <i className='ki-outline ki-sms fs-4 me-1' /> "alksjf"
                        </a> */}
                  </div>
                </div>
                <div className='d-flex my-4'>
                  {DeviceSpectifications?.type === 'Public' ? (
                    <a href='#' className={`btn btn-sm btn-success me-3`}>
                      Public
                    </a>
                  ) : (
                    <a href='#' className={`btn btn-sm btn-danger me-3`}>
                      Private
                    </a>
                  )}
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <i className='ki-outline ki-arrow-down fs-3 text-danger me-2' />{' '} */}
                        <div
                          className='fs-2 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={80}
                          data-kt-initialized={1}
                        >
                          {DeviceSpectifications?.chargers.length}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-500'>Charger's</div>
                    </div>
                    <Link to={`/add-charger-station/${pointId}`}>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg-light-success'>
                        <div className='d-flex align-items-center'>
                          {/* <i className='ki-outline ki-plus fs-3 text-success me-2' />{' '} */}

                          <div
                            className='fs-1 fw-bold counted text-center'
                            data-kt-countup='true'
                            data-kt-countup-value={80}
                            data-kt-initialized={1}
                          >
                            <KTIcon iconName='abstract-10' className='fs-1 text-success' />
                          </div>
                        </div>
                        <div className='fw-semibold fs-6 text-gray-900'>Add Charger</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='table-responsive'>
        <h1>Chargers in the Station</h1>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-4 rounded-1'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-250px rounded-start'>Charger Name</th>
                <th className='min-w-200px'>OCPP ID</th>
                <th className='min-w-150px'>Communication</th>
                <th className='min-w-150px'>Charger Type</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-200px text-center rounded-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {DeviceSpectifications?.chargers?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            src={
                              item?.image ? item?.image : toAbsoluteUrl('/media/avatars/blank.png')
                            }
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/charger/' + item?.id}
                            className='text-dark fw-bold text-hover-success mb-1 fs-6'
                          >
                            {item?.name}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {item?.hub?.name}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td >
                      <span className='text-dark fw-bold  d-block mb-1 fs-6'>  {item?.stationId}</span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.communication}
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Communication
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold  d-block mb-1 fs-6'>
                        {item?.charge_type}
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Charger Type
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          item?.status == 'Active'
                            ? 'badge fs-7 fw-semibold badge-light-success'
                            : 'badge fs-7 fw-semibold badge-light-danger'
                        }
                      >
                        {item?.status}
                      </span>
                    </td>
                    <td className='text-center'>
                      <Link
                        to={'/edit-charger/' + item?.id}
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#confirm_modal'
                        onClick={() => {
                          setSelectedId(item?.id)
                        }}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <ConfirmModal
          text='Do You Want To Delete this Charger(Device)?'
          handleSubmit={DeleteHandler}
        />
      </div>
    </div>
  )
}

export default HubDeviceSpecification
