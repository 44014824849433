import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast';
import { KTSVG } from '../../../_metronic/helpers';
import { getRequest, putRequest } from '../Helpers/Requests';

export default function EditConnectorType({ id, getAllConnectorType }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
        image: null,
        powerType: '',
    })



    const handleSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append("image", values.image);
        formData.append("name", values.name);
        formData.append("powerType", values.powerType);
        try {
            const res = await putRequest('/connector-type/' + id, formData)
            if (res && res.data) {
                getAllConnectorType()
                toast.success(res.data.message)
            }
        } catch (error: any) {
            console.log(error)
            toast.error(error.response?.data?.message)
        }
    }
    const getAmenityById = async () => {
        try {
            const res = await getRequest('/connector-type/' + id)
            if (res) {
                setInitialValues({ ...res.data.connectorType, image: null })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAmenityById()
    }, [id])

    return (
        <div className='modal fade' tabIndex={-1} id='edit_connector'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header bg-success'>
                        <h5 className='modal-title text-white'>Edit Car</h5>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-success ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>
                    <div className='modal-body'>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue, touched }) => (
                                <Form>
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Car Name</label>
                                            <Field placeholder='Car Name' name='name' className='form-control' type="text" />
                                            <ErrorMessage name='name' component='div' className='text-danger' />
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Power Type</label>
                                            <Field as='select' name='powerType' className='form-control'>
                                                <option value=''>Select Power Type</option>
                                                {[
                                                    'AC',
                                                    'DC',
                                                ].map((item: any) => {
                                                    return (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    )
                                                })}
                                            </Field>
                                            <ErrorMessage name='powerType' component='div' className='text-danger' />
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className='form-label'>Connector image</label>
                                            <input placeholder='Image' type='file' className='form-control'
                                                onChange={(event: any) => {
                                                    setFieldValue('image', event.target.files[0])
                                                }} />
                                            <ErrorMessage name='image' component='div' className='text-danger' />
                                        </div>

                                    </div>
                                    <div className='modal-footer mt-3'>
                                        <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal'>
                                            Cancel
                                        </button>
                                        <button type='submit' className='btn btn-light-success' data-bs-dismiss='modal'>
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}


