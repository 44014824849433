import React, { useEffect, useState } from 'react'
import { getRequest } from './../../../../app/pages/Helpers/Requests'
import { KTIcon } from '../../../helpers'
import { getDateFormat } from '../../../../functions'
type Props = {
  className: string
  stationId: string
}

const tableHeaders = {
  userId: 'User Id',
  dateTime: 'Date & Time',
  name: 'Name',
  mobileNumber: 'Mobile Number',
  email: 'Email',
  amount: 'Amount',
  platform: 'Platform',
  stopFrom: 'Stop From',
  status: 'Status',
  reason: 'Reason',
}

const TableWidgetCS: React.FC<Props> = ({ className, stationId }) => {
  const [page, setPage] = useState<number>(1)
  const [sessions, setSessions] = useState<any[]>([])
  const [limit] = useState<number>(8)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  const getPlatformStatusClass = (status: string) => {
    switch (status) {
      case 'NotStarted':
        return 'badge badge-danger'
      case 'Completed':
        return 'badge badge-success'
      case 'Started':
        return 'badge badge-warning'
      default:
        return 'badge badge-secondary'
    }
  }

  const getSessions = async () => {
    try {
      setLoading(true)
      const res = await getRequest(
        `/session?stationId=${stationId}&type=all&page=${page}&limit=${limit}`
      )
      if (res?.status === 200) {
        setSessions([...sessions, ...res?.data?.data])
        setTotalPages(res?.data?.pagination?.totalPages)
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching sessions:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (stationId) {
      getSessions()
    }
  }, [stationId, page])

  return (
    <div className={`card ${className}`}>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-striped gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-black border-bottom-2 border-gray-200'>
              {Object.values(tableHeaders).map((header, index) => (
                <th key={index} className='min-w-210px'>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sessions.map((item, index) => {
              const createdAt = new Date(item.createdAt)
              const formattedDate = getDateFormat(item.createdAt)
              const formattedName = `${item.user?.firstName} ${item.user?.lastName || ''}`
              const mobileNumber = item.user?.mobileNumber
              const email = item.user?.email
              const platform = item.platform
              const stopfrom = item?.stopFrom
              const status = item.status
              const amount = item.maxAmount
              const userId = item?.user?.userId
              const reason = item.reason
              return (
                <tr key={index}>
                  <td>{userId}</td>
                  <td>{formattedDate}</td>
                  <td>{formattedName}</td>
                  <td>{mobileNumber}</td>
                  <td>{email || 'Not Available'}</td>
                  <td>₹{amount}</td>
                  <td>{platform || 'Not Available'}</td>
                  <td>{stopfrom || 'Not Available'}</td>
                  <td>
                    <span className={getPlatformStatusClass(status)}>{status}</span>
                  </td>
                  <td>{reason}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {page < totalPages && (
        <div className='w-100 d-flex align-items-center justify-content-center py-3 '>
          <button
            type='button'
            disabled={loading}
            className='btn btn-light btn-sm btn-light-primary'
            onClick={() => {
              setPage(page + 1)
            }}
          >
            More <i className='fa-solid fa-chevron-down'></i>
          </button>
        </div>
      )}
    </div>
  )
}

export { TableWidgetCS }
