import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {newRfidValidation} from '../js/NewRfidValidation'
import toast, {Toaster} from 'react-hot-toast'
import {postRequest, getRequest} from '../Helpers/Requests'

const AddTags = () => {
  const {pointId} = useParams()
  const navigate = useNavigate()
  const [userList, setUserList] = useState([])
  const [masterTags, setMasterTags] = useState([])
  const [initialValues, setInitialValues] = useState({
    rfIdTag: '',
    userId: '',
    expiryDate: '',
    masterRfidTag: null,
    comments: '',
  })

  // Fetch master tags
  const fetchMasterTags = async (userId: any) => {
    try {
      const res = await getRequest(`/rfids/master/${userId}`)
      if (res?.data?.data) {
        setMasterTags(res.data.data)
        console.log(res.data.data)
      }
    } catch (error) {
      console.error('Error fetching master tags:', error)
    }
  }

  const fetchUsers = async () => {
    try {
      const url = pointId ? `/ev/users/${pointId}` : '/ev/users/'
      const res = await getRequest(url)
      if (res?.data?.data) {
        if (pointId) {
          setInitialValues((prev) => ({
            ...prev,
            userId: res.data.data.EvUser?.id || '',
          }))
          console.log('users at addTags', res.data.data.EvUser.id)
          await fetchMasterTags(res.data.data.EvUser?.id)
        } else {
          setUserList(res.data.data)
        }
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  // Handle form submission
  const handleSubmit = async (values) => {
    try {
      const res = await postRequest('/rfids', values)
      if (res?.data) {
        toast.success('RFID Tag Created Successfully ✅')
        setTimeout(() => navigate('/Evusers/EV/RfidTags'), 1000)
      }
    } catch (error) {
      toast.error('Failed to Create RFID Tag ❌')
      console.error(error)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <div>
      <Toaster position='top-right' reverseOrder={true} />
      <div className='card card-custom'>
        <div className='card-header text-center'>
          <h3 className='card-title text-black'>CREATE NEW RFID TAG</h3>
        </div>
        <div className='card-body'>
          <Formik
            initialValues={initialValues}
            validationSchema={newRfidValidation}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({errors, setFieldValue}) => (
              <Form>
                <div className='row'>
                  <div className='col-md-6 mb-10'>
                    <Field
                      type='text'
                      className='form-control'
                      name='rfIdTag'
                      placeholder='RFID Tag'
                    />
                    <span className='text-danger fs-5'>{errors.rfIdTag}</span>
                  </div>
                  <div className='col-md-6 mb-10'>
                    {!pointId ? (
                      <Field
                        as='select'
                        className='form-select'
                        name='userId'
                        onChange={async (e: any) => {
                          const selectedUserId = e.target.value
                          const selectedUser: any = userList.find(
                            (user: any) => user.id === parseInt(selectedUserId)
                          )
                          if (selectedUser) {
                            setFieldValue('userId', selectedUser.id)
                            await fetchMasterTags(selectedUser.id)
                          }
                        }}
                      >
                        <option value=''>Select User</option>
                        {userList.map((user: any) => (
                          <option key={user.id} value={user.id}>
                            {`${user.firstName} ${user.lastName}`}
                          </option>
                        ))}
                      </Field>
                    ) : (
                      <Field type='text' className='form-control' name='userId' disabled />
                    )}
                    <span className='text-danger fs-5'>{errors.userId}</span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mb-10'>
                    <Field type='date' className='form-control' name='expiryDate' />
                    <span className='text-danger fs-5'>{errors.expiryDate}</span>
                  </div>
                  <div className='col-md-6 mb-10'>
                    <Field as='select' className='form-select' name='masterRfidTag'>
                      <option value=''>Select Master RFID Tag</option>
                      {masterTags?.map((tag: any) => (
                        <option key={tag.id} value={tag.id}>
                          {tag.rfIdTag}
                        </option>
                      ))}
                    </Field>
                    <span className='text-danger fs-5'>{errors.masterRfidTag}</span>
                  </div>
                </div>

                {/* Comments */}
                <div className='mb-10'>
                  <Field
                    as='textarea'
                    className='form-control'
                    name='comments'
                    placeholder='Comments'
                    rows='3'
                  />
                  <span className='text-danger fs-5'>{errors.comments}</span>
                </div>

                {/* Buttons */}
                <div className='d-flex justify-content-end'>
                  <button type='reset' className='btn btn-light-danger me-2'>
                    Reset
                  </button>
                  <button type='submit' className='btn btn-light-success me-2'>
                    Submit
                  </button>
                  <Link to='/Evusers/EV/RfidTags'>
                    <button type='button' className='btn btn-light-primary'>
                      Back
                    </button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AddTags
