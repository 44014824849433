import * as Yup from 'yup';

export const TarrifValidation = Yup.object({
    name: Yup.string().min(3).required('Please enter Tariff Name '),
    operatorId: Yup.number().required('Please enter operator'),
    fixedPrice: Yup.object({
        price: Yup.number()
            .required('Price is required')
            .positive('Price must be a positive number')
            .min(1, 'Price must be greater than zero'),
        gst: Yup.number()
            .required('GST percentage is required')
            .min(0, 'GST percentage cannot be less than 0')
            .max(100, 'GST percentage cannot be more than 100'),
    }).required('Price details are required'),
})