import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getRequest} from '../Helpers/Requests'
import DeviceManagement from './DeviceManagement'
import DeviceSpecification from './DeviceSpecification'
import DeviceConfiguration from './DeviceConfiguration'
import DeviceTransaction from './DeviceTransaction'
import DeviceLogos from './DeviceLogos'
import StationQrCode from './StationQrCode'
import StationSettings from './StationSettings'
import StationChargeSessions from './StationChargeSessions'
const headings = [
  'Device Specification',
  'Device Management',
  'Device Configuration',
  'Device Transaction',
  'Device Logs',
  // 'Amenities',
  // 'Station Images',
  'QR Codes',
  'Settings',
  'Charging Sessions',
]

const StationDetailsHeader = () => {
  const [stationId, setStationId] = useState('')
  const [connectors, setConnectors] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [stationName, setStationName] = useState('')
  const {pointId} = useParams()

  useEffect(() => {
    const fetchStationDetails = async () => {
      try {
        const res = await getRequest(`/charger/${pointId}`)
        if (res?.data) {
          let data = res.data.data
          setStationName(data.name)
          setStationId(data.stationId)
          setConnectors(data.connectors)
        }
      } catch (error) {
        console.error('Error fetching station details:', error)
      }
    }

    fetchStationDetails()
  }, [pointId])

  useEffect(() => {
    const storedTab = localStorage.getItem('selectedTab')
    if (storedTab !== null) {
      setActiveTab(parseInt(storedTab))
    }
  }, [])

  const handleTabClick = (index: any) => {
    setActiveTab(index)
    localStorage.setItem('selectedTab', index.toString())
  }

  return (
    <div>
      <div className='text-end mb-5'></div>
      <div className='card card-custom'>
        <div className='card-body mt-5'>
          <h1 className='mb-5'>{stationName}</h1>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5'>
            {headings?.map((heading, index) => (
              <li className='nav-item' key={index}>
                <a
                  className={`nav-link ${activeTab === index ? 'active' : ''}`}
                  data-bs-toggle='tab'
                  href={`#kt_tab_pane_${index}`}
                  onClick={() => handleTabClick(index)}
                >
                  {heading}
                </a>
              </li>
            ))}
          </ul>
          <div className='tab-content' id='myTabContent'>
            {activeTab === 0 && <DeviceSpecification />}
            {activeTab === 1 && (
              <DeviceManagement stationId={stationId} id={pointId} connectors={connectors} />
            )}
            {activeTab === 2 && <DeviceConfiguration stationId={stationId} />}
            {activeTab === 3 && <DeviceTransaction stationId={stationId} />}
            {activeTab === 4 && <DeviceLogos stationId={stationId} />}
            {activeTab === 5 && <StationQrCode stationId={stationId} connectors={connectors} />}
            {activeTab === 6 && <StationSettings stationId={stationId} />}
            {activeTab === 7 && <StationChargeSessions stationId={stationId} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationDetailsHeader
