import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  connectorId: Yup.string().required('Connector is required'),
  message: Yup.string().required('Messasge is required'),
})

const messages = [
  'BootNotification',
  'DiagnosticsStatusNotification',
  'FirmwareStatusNotification',
  'Heartbeat',
  'MeterValues',
  'StatusNotification',
]

function TriggerMessage({stationId, connectors}) {
  const [initialValues, setInitialValues] = useState({
    connectorId: '',
    message: '',
  })
  const handleSubmit = async (values, {resetForm}) => {
    try {
      const res = await postRequest('/ocpp/remote/trigger-message/' + stationId, values)
      if (res && res.data) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='trigger_message'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Trigger Message</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select Connector</label>
                        <Field as='select' name='connectorId' className='form-control'>
                          <option value=' '>Select Connector</option>
                          {connectors.map((connector) => {
                            return (
                              <option value={connector.connector_ID}>
                                {connector.connector_ID} {connector.connectorType.name}
                              </option>
                            )
                          })}
                        </Field>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Expiry Date</label>
                        <Field as='select' name='message' className='form-control'>
                          <option value=''>Select Trigger Message</option>
                          {messages.map((message) => (
                            <option value={message}>{message}</option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TriggerMessage
