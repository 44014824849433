import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getRequest} from '../../../../app/pages/Helpers/Requests'

function EditedAmenitiesModal({stationId}) {
  const [newAmenities, setNewAmenities] = useState([])
  const [oldAmenities, setOldAmenities] = useState([])
  const [deletedAmenities, setDeletedAmenties] = useState([])

  const getRequestByStationId = async () => {
    try {
      const res = await getRequest('/amenity-change/station/' + stationId)
      console.log(res)
      if (res?.status === 200) {
        const amenities = res.data.data
        const newList = amenities?.filter((item: any) => {
          if (item.newAmenity === 1) {
            return item
          }
        })
        const oldList = amenities.filter((item: any) => {
          if (item.oldAmenity === 1) {
            return item
          }
        })
        const delList = amenities.filter((item: any) => {
          if (item.deleted === 1) {
            return item
          }
        })
        console.log(newList, oldList, delList)
        setNewAmenities(newList)
        setOldAmenities(oldList)
        setDeletedAmenties(delList)
        console.log(res?.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRequestByStationId()
  }, [stationId])

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='amenity_request_details'>
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content shadow-none'>
            <div className='modal-header'>
              <h5 className='modal-title'>Amenities Change Request</h5>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div>
                {/* Complimentarty Services:: Starts:: Here */}
                <div className='card card-custom'>
                  <div className='card-header flex-nowrap border-0 pt-9'>
                    <div className='card-title m-0'>
                      <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                        New Amenities
                      </a>
                    </div>

                    <div className='card-toolbar m-0'></div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      {newAmenities.map((e: any, index: any) => {
                        return (
                          <div className='col-md-3' key={index}>
                            <div className='mb-10'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='New Amenities List'
                                  id='Restrooms'
                                  checked={true}
                                />
                                <label
                                  className='form-check-label d-flex align-items-center fs-5  text-dark'
                                  htmlFor='Restrooms'
                                >
                                  <i
                                    className={`fa-solid ${e.amenity.icon} fs-3 fw-bold text-dark me-3`}
                                  ></i>
                                  {e.amenity.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className='card card-custom mt-15 mb-10'>
                  <div className='card-header flex-nowrap border-0 pt-9'>
                    <div className='card-title m-0'>
                      <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                        Old Amenities
                      </a>
                    </div>

                    <div className='card-toolbar m-0'></div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      {oldAmenities.map((e: any, index: any) => {
                        return (
                          <div className='col-md-3' key={index}>
                            <div className='mb-10'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='NearBy'
                                  id='Restrooms'
                                  checked={true}
                                />
                                <label
                                  className='form-check-label d-flex align-items-center fs-5  text-dark'
                                  htmlFor='Tea/Coffee'
                                >
                                  <i
                                    className={`fa-solid ${e.amenity.icon} fs-3 fw-bold text-dark me-3`}
                                  ></i>

                                  {e.amenity.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className='card card-custom mt-15 mb-10'>
                  <div className='card-header flex-nowrap border-0 pt-9'>
                    <div className='card-title m-0'>
                      <a href='#' className='fs-1 fw-bold  text-dark m-0'>
                        Deleted Amenities
                      </a>
                    </div>

                    <div className='card-toolbar m-0'></div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      {deletedAmenities.map((e: any, index: any) => {
                        return (
                          <div className='col-md-3' key={index}>
                            <div className='mb-10'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='NearBy'
                                  id='Restrooms'
                                  checked={true}
                                />
                                <label
                                  className='form-check-label d-flex align-items-center fs-5  text-dark'
                                  htmlFor='Tea/Coffee'
                                >
                                  <i
                                    className={`fa-solid ${e.amenity.icon} fs-3 fw-bold text-dark me-3`}
                                  ></i>

                                  {e.amenity.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditedAmenitiesModal
