/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {TablesWidget99} from './../../../_metronic/partials/widgets/tables/TableWidget99'
import {servicedata} from './../js/ServiceData'
import Top from './../../modules/Chargestations/Charge/components/Top'
import {getRequest} from '../Helpers/Requests'
import {useParams} from 'react-router-dom'
const TransactionCPO = ({stations}) => {
  const {pointId} = useParams()
  const [stationId, setStationId] = useState<any>('')
  const [transactions, setTransactions] = useState([])
  const [chargers, setChargers] = useState<any>([])
  const [hubs, setHubs] = useState<any>([])
  const [hubId, setHubId] = useState<any>()

  const getAllDeviceTransactionsByStationId = async () => {
    try {
      const res = await getRequest('/device-transaction/station/' + stationId)
      if (res?.status === 200) {
        setTransactions(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value
    console.log(selectedValue)
    setStationId(selectedValue)
  }

  useEffect(() => {
    if (stationId !== '' && stationId !== null && stationId !== undefined) {
      getAllDeviceTransactionsByStationId()
    }
  }, [stationId])

  useEffect(() => {
    setStationId(chargers[0]?.stationId)
  }, [chargers])
  useEffect(() => {
    setHubId(hubs[0]?.id)
  }, [hubs])

  const getAllChargersOfCpo = async () => {
    try {
      const res = await getRequest('/charger/hub/' + hubId)
      if (res?.status === 200) {
        setChargers(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllHubsOfCpo()
  }, [])

  const handleHubChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value
    console.log(selectedValue)
    setHubId(selectedValue)
  }

  const getAllHubsOfCpo = async () => {
    try {
      const res = await getRequest('/hub/hubs/by-operator/' + pointId)
      if (res?.status === 200) {
        setHubs(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllChargersOfCpo()
  }, [hubId])

  return (
    <>
      <div className='row mb-5'>
        <div className='col-md-4 d-flex justify-content-start'>
          <div className='me-5'>
            <label className='fs-5 fw-bold'>Station Selected</label>
            <select
              className='form-select me-5'
              aria-label='Select example'
              onChange={handleHubChange}
              value={hubId}
            >
              <option>Select Station</option>
              {hubs?.map((item: any, index: any) => {
                return (
                  <option
                    key={index}
                    value={`${item?.id}`}
                  >{`${item?.name} (${item?.hubId})`}</option>
                )
              })}
            </select>
          </div>

          <div>
            <label className='fs-5 fw-bold'>Charger Selected</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleChange}
              value={stationId}
            >
              <option>Select Station</option>
              {chargers?.map((item: any, index: any) => {
                return (
                  <option
                    key={index}
                    value={`${item?.stationId}`}
                  >{`${item?.name} (${item?.stationId})`}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      {stationId === '' && <h1>Please Select Station to Check Transactions</h1>}
      {transactions && stationId !== '' && (
        <TablesWidget99 transactions={transactions} className='card-xxl-stretch mb-5 mb-xl-8' />
      )}
    </>
  )
}

export default TransactionCPO
