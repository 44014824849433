/* eslint-disable */

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from '../../API/Api_Instance'
import {OperatorInformationHeading} from '../Helpers/TableHeadings'
import {CMSUSERSupdate} from '../Helpers/Interfaces'
import {getRequest} from '../Helpers/Requests'
import StationLogs from '../layout-builder/StationLogs'
import {TableWidget98} from './../../../_metronic/partials/widgets/tables/TableWidget98'
import TransactionCPO from '../layout-builder/TransactionCPO'
import FinancialSettlements from '../layout-builder/FinancialSettlements'
import Tariff from '../layout-builder/Tariff'
import AmenitiesCPO from '../layout-builder/AmenitiesCPO'

const Tabs = () => {
  let {pointId} = useParams()
  const [cmsUsers, setCmsUsers] = useState<CMSUSERSupdate | null>(null)
  const [stations, setStations] = useState<any>([])

  // function to get user details
  const getUserById = async () => {
    try {
      const res = await getRequest(`/cms/users/${pointId}`)
      if (res && res.data) {
        console.log(res.data)
        setCmsUsers(res.data.data.cmsUser)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getStationsByCpoId = async () => {
    try {
      const response = await getRequest(`/hub/hubs/by-operator/${pointId}`)
      console.log(response)
      if (response?.status === 200) {
        console.log(response.data.data)
        setStations(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (pointId !== null && pointId !== ' ' && pointId !== undefined) {
      getUserById()
      getStationsByCpoId()
    }
  }, [])

  return (
    <div>
      <div className='card mb-0 mb-xl-2' id='kt_profile_details_view'>
        <div className='card-body p-5'>
          <div className='card mb-6'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img src='/media/avatars/blank.png' alt='image' />
                    <div
                      className={`position-absolute translate-middle bottom-0 start-100 mb-6 ${
                        cmsUsers?.status === 1 ? 'bg-success' : 'bg-danger'
                      } rounded-circle border border-4 border-body h-20px w-20px`}
                    />
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                          {cmsUsers?.OperatorName}
                        </a>
                        <a>
                          <i className='ki-outline ki-verify fs-1 text-primary' />
                        </a>
                      </div>
                      <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                        <a className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'>
                          <i className='ki-outline ki-phone fs-4 me-1' />
                          +91 {cmsUsers?.mobileNumber}
                        </a>
                        <a className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'>
                          <i className='ki-outline ki-geolocation fs-4 me-1' /> {cmsUsers?.address1}
                        </a>
                        <a className='d-flex align-items-center text-gray-500 text-hover-primary mb-2'>
                          <i className='ki-outline ki-sms fs-4 me-1' /> {cmsUsers?.email}
                        </a>
                      </div>
                    </div>
                    <div className='d-flex my-4'>
                      {cmsUsers?.status === 1 ? (
                        <a className={`btn btn-sm btn-success me-3`}>Active</a>
                      ) : (
                        <a className={`btn btn-sm btn-danger me-3`}>Inactive</a>
                      )}
                    </div>
                  </div>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-outline ki-arrow-down fs-3 text-danger me-2' />{' '}
                            <div
                              className='fs-2 fw-bold counted'
                              data-kt-countup='true'
                              data-kt-countup-value={80}
                              data-kt-initialized={1}
                            >
                              {cmsUsers?.AMCend ? cmsUsers?.AMCend : 'Null'}
                            </div>
                          </div>
                          <div className='fw-semibold fs-6 text-gray-500'>AMC End date</div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-outline ki-arrow-up fs-3 text-success me-2' />{' '}
                            <div
                              className='fs-2 fw-bold counted'
                              data-kt-countup='true'
                              data-kt-countup-value={60}
                              data-kt-countup-prefix='%'
                              data-kt-initialized={1}
                            >
                              {cmsUsers?.AMCstart ? cmsUsers?.AMCstart : 'Null'}
                            </div>
                          </div>
                          <div className='fw-semibold fs-6 text-gray-500'>AMC Start Date</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-success py-5 me-6'
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_1'
                  >
                    Stations
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-success py-5 me-6'
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_2'
                  >
                    Financial Reports/Settlements
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-success py-5 me-6'
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_3'
                  >
                    Transactions
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-success py-5 me-6'
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_4'
                  >
                    Tariff
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-success py-5 me-6'
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_5'
                  >
                    Amenities
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='cartd-body p-5'>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade' id='kt_tab_pane_1' role='tabpanel'>
              <TableWidget98 className='mb-1 mb-xl-2' stations={stations} />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
              <FinancialSettlements stations={stations} />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
              <TransactionCPO stations={stations} />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
              <Tariff />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
              <AmenitiesCPO stations={stations} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
