/* eslint-disable */

import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Link, useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import QRScanner from '../../../../app/UI/QRScanner'
import toast, {Toaster} from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'
import {deleteRequest, getRequest} from '../../../../app/pages/Helpers/Requests'
import {AllChargeStationTableHeadings} from '../../../../app/pages/Helpers/TableHeadings'
import ConfirmModal from '../../../../app/modules/Confirmation/ConfirmModal'

const AllChargers = ({className}) => {
  const {pointId}=useParams();
  const [Stations, setStations] = useState([])
  const [value, setValues] = useState<any>(null)
  const [seletedId, setSelectedId] = useState<any>()
  // Function for getting all stations
  const getAllStations = async () => {
    try {
      const res = await getRequest(`/charger/hub/${pointId}`)
      console.log(res?.data.data)
      if (res && res.data) {
        setStations(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Function for handling delete request
  const DeleteHandler = async () => {
    try {
      const res = await deleteRequest(`/charger/${seletedId}`)
      console.log(res, res?.data)
      toast.success('Deleted SuccessFully')
      getAllStations()
    } catch (error) {
      console.log(error)
      // toast.error(error)
    }
  }

  function ChargeBoxId(id) {
    const StationFliter = Stations.find((e: any) => e.id === id)
    console.log(StationFliter)
    setValues(StationFliter)
  }

  useEffect(() => {
    getAllStations()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            List of Chargers
          </span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/add-charger-station' className='btn btn-sm btn-light-success'>
            <KTIcon iconName='plus' className='fs-2' />
            Add Charger
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-250px rounded-start'>
                  Charger Name
                </th>
                <th className='min-w-200px'>OCPP ID</th>
                <th className='min-w-150px'>Communication</th>
                <th className='min-w-150px'>Charger Type</th>
                <th className='min-w-100px'>{AllChargeStationTableHeadings.status}</th>
                <th className='min-w-200px text-center rounded-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Stations.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            src={
                              item?.image ? item?.image : toAbsoluteUrl('/media/avatars/blank.png')
                            }
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={'/StationDetails/' + item?.id}
                            className='text-dark fw-bold text-hover-success mb-1 fs-6'
                          >
                            {item?.name}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {item?.hub?.name}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td className='text-dark fw-bold  d-block mb-1 fs-6'>{item?.stationId}</td>

                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item?.communication}</a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Communication</span>
                    </td>
                    <td>
                      <a className='text-dark fw-bold  d-block mb-1 fs-6'>{item?.charge_type}</a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Charger Type</span>
                    </td>
                    <td>
                      <span
                        className={
                          item?.status == 'Active'
                            ? 'badge fs-7 fw-semibold badge-light-success'
                            : 'badge fs-7 fw-semibold badge-light-danger'
                        }
                      >
                        {item?.status}
                      </span>
                    </td>
                    <td className='text-end'>
                      

                      <Link
                        to={'/edit-charger/' + item?.id}
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#confirm_modal'
                        onClick={() => {
                          // DeleteHandler(item.id)
                          setSelectedId(item?.id)
                        }}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <ConfirmModal text='Do You Want To Delete this Charger(Device)?' handleSubmit={DeleteHandler} />
      </div>
    </div>
  )
}

export {AllChargers}
