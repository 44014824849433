/* eslint-disable */

import {useState} from 'react'
import {Formik, Form} from 'formik'
import toast from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {ChargeStationInitialValues} from '../Helpers/AllInitialValues'
import api_instance from '../../API/Api_Instance'
import * as Yup from 'yup'
import ChargerGeneralDetail from './ChargerGeneralDetail'

const ChargeStationValidationSchema = Yup.object().shape({
  hubId: Yup.string().required('Station ID is required'),
  name: Yup.string().required('Charger name is required'),
  stationId: Yup.string().required('Station ID is required'),
  communication: Yup.string().required('Communication type is required'),
  charge_type: Yup.string().required('Charge type is required'),
  status: Yup.string().required('Status is required'),
  state: Yup.string().required('State is required'),
  connectors: Yup.array()
    .of(
      Yup.object().shape({
        connector_ID: Yup.number().required('Connector ID is required'),
        port_type: Yup.string().required('Port type is required'),
        power_output: Yup.string().required('Power output is required'),
        tariffId: Yup.string().required('Tariff ID is required'),
      })
    )
    .required('At least one connector is required')
    .min(1, 'At least one connector is required'),
})

const AddChargerToHub = () => {
  const [openAmenities, setOpenAmenities] = useState(false)
  const {stationId} = useParams()
  const navigate = useNavigate()
  const handleSubmit = async (values: any, formikBag: any) => {
    try {
      const res = await api_instance.post(`/charger`, {...values, hubId: stationId})
      if (res && res.data) {
        toast.success('Charge Station Created Successfully')
        setTimeout(() => {
          formikBag.resetForm()
          navigate(-1)
        }, 1000)
      }
    } catch (error) {
      toast.error('Invalid Data Check Data ')
      console.error('Error:', error)
    }
  }

  return (
    <div className='mt-0'>
      <div className='card card-custom'>
        <div className='card-header d-flex justify-content-center bg-success'>
          <h3 className='card-title text-white'>ADD CHARGE STATION</h3>
        </div>
        <div className='card-body mt-5'>
          <Formik initialValues={ChargeStationInitialValues} onSubmit={handleSubmit}>
            {({errors, values, setFieldValue, touched}) => (
              <Form>
                <div className='mt-15'>
                  <div className={`${openAmenities ? 'd-none' : 'd-block'}`}>
                    <ChargerGeneralDetail
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                    <div className='d-flex justify-content-end mt-10'>
                      <div className='mb-10 me-5'>
                        <button type='submit' className='btn btn-light-success'>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AddChargerToHub
