import React, {useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, ErrorMessage, Field} from 'formik'
import {KTSVG} from '../../../helpers'
import {postRequest} from '../../../../app/pages/Helpers/Requests'
import toast from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  connectorId: Yup.string().required('Connector is required'),
  userId: Yup.string().required('User Id is required'),
  amount: Yup.number().required('Amount is required').positive('Amount must be a positive number'),
})

function RemoteStartTransaction({stationId, connectors}) {
  const [initialValues, setInitialValues] = useState({
    connectorId: '',
    ocpp_idTag: '',
    amount: '',
  })

  const handleSubmit = async (values, {resetForm}) => {
    try {
      console.log(values)
      const res = await postRequest('/ocpp/remote/start-transaction/' + stationId, values)
      if (res && res.data) {
        toast.success(res.data.message)
        resetForm()
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='remote_startTransaction'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Remote Start Transaction</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, touched}) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Select Connector</label>
                        <Field as='select' name='connectorId' className='form-control'>
                          <option value=''>Select Connector</option>
                          {connectors.map((connector) => (
                            <option key={connector?.connector_ID} value={connector?.connector_ID}>
                              {connector.connector_ID} {connector?.connectorType?.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name='connectorId' component='div' className='text-red-500' />
                      </div>

                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>User Id</label>
                        <Field name='userId' className='form-control' />
                        <ErrorMessage name='userId' component='div' className='text-red-500' />
                      </div>

                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Amount</label>
                        <Field
                          type='number'
                          name='amount'
                          className='form-control'
                          placeholder='Enter amount'
                        />
                        <ErrorMessage name='amount' component='div' className='text-red-500' />
                      </div>
                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RemoteStartTransaction
