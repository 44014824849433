/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {getRequest} from '../../../../app/pages/Helpers/Requests'
import {Tooltip} from 'react-tooltip'
type Props = {
  className: string
  tableone: {}
  stationId: any
}

export interface tableoneUpdate {
  config: string
  value: string
  description: string
  access: string
}

export const Tableone = {
  config: 'Configuration Name',
  value: 'Configuration Value',
  description: 'Configuration Description',
  access: 'Accessibility',
}

interface TableOneUpdate {
  config: string
  value: string
  description: string
  access: string
}

const TABLE_HEADERS: TableOneUpdate = {
  config: 'Configuration Name',
  value: 'Configuration Value',
  description: 'Configuration Description',
  access: 'Accessibility',
}

const TableWidgetCD1: React.FC<Props> = ({className, stationId}) => {
  const [configuration, setConfiguration] = useState([])

  const getAllConfigurationKey = async () => {
    try {
      const res = await getRequest('/config/' + stationId)
      console.log(res)
      if (res?.status === 200) {
        setConfiguration(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stationId !== null && stationId !== '' && stationId !== undefined) {
      getAllConfigurationKey()
    }
  }, [stationId])

  return (
    <div className={`card ${className}`}>
      <div className='table-responsive'>
        <table className='table table-bordered table-striped text-center'>
          <thead className='thead-light'>
            <tr>
              <th style={{width: '25%'}}>{TABLE_HEADERS.config}</th>
              <th style={{width: '100px'}}>{TABLE_HEADERS.value}</th>
              <th style={{width: '25%'}}>{TABLE_HEADERS.description}</th>
              <th style={{width: '25%'}}>{TABLE_HEADERS.access}</th>
            </tr>
          </thead>
          <tbody>
            {configuration.map((item: any, index) => {
              console.log(item)
              return (
                <tr key={index}>
                  <td
                    style={{
                      width: '25%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.configName}
                  </td>
                  <td
                    style={{
                      width: '25%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <a
                      data-tooltip-id={index.toString()}
                      data-tooltip-content={item?.configValue}
                      data-tooltip-place='top'
                    >
                      {item?.configValue?.slice(0, 70)}
                    </a>
                    <Tooltip id={index.toString()} />
                  </td>
                  <td
                    style={{
                      width: '25%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <a
                      data-tooltip-id={index.toString()}
                      data-tooltip-content={item.configDescription}
                      data-tooltip-place='top'
                    
                    >
                      {item?.configDescription?.slice(0, 70)}
                    </a>
                    <Tooltip style={{backgroundColor: '#5DBF88'}} id={index.toString()} />
                  </td>
                  <td
                    style={{
                      width: '25%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.accessibility}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export {TableWidgetCD1}
