import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers';
import { getRequest, putRequest } from '../Helpers/Requests';
import { CarBrandValidation } from '../js/CarBrandValidation';

export default function EditCarBrand({ id, getAllBrands }) {
  const [initialValues, setInitialValues] = useState({
    brandName: '',
    image: null,
  })

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("brandName", values.brandName);
    if (values.image) {
      formData.append("image", values.image);
    }

    formData.forEach((value, index) => {
      console.log(index, value);
    })

    try {
      const res = await putRequest('/car-brand/' + id, formData)
      if (res && res.data) {
        getAllBrands()
        toast.success(res.data.message)
        resetForm()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }
  const getCarBrandById = async () => {
    try {
      const res = await getRequest('/car-brand/' + id)
      if (res) {
        console.log(res?.data?.carBrand);
        setInitialValues({
          brandName: res?.data?.carBrand?.brandName || '',
          image: null,
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCarBrandById()
  }, [id])

  return (
    <div className='modal fade' tabIndex={-1} id='edit_carBrand'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header bg-success'>
            <h5 className='modal-title text-white'>Edit Car Brand</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-success ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={CarBrandValidation}
            >
              {({ values, setFieldValue, touched }) => (
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Name</label>
                        <Field placeholder='Car Brand Name' name='brandName' className='form-control' />
                        <ErrorMessage name='brandName' component="div" className='text-danger' />
                      </div>

                      <div className='col-md-12 mt-3'>
                        <label className='form-label'>Brand Logo</label>
                        <input placeholder='Image' type='file' className='form-control'
                          onChange={(event: any) => {
                            setFieldValue('image', event.target.files[0])
                          }} />
                        <ErrorMessage name='image' component='div' className='text-danger' />
                      </div>

                    </div>
                    <div className='modal-footer mt-3'>
                      <button
                        type='button'
                        className='btn btn-light-danger'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-light-success'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
