/* eslint-disable */
import React from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { loactindata } from '../../../../app/pages/js/locationdata'
import { ChargeDay } from '../../../../app/pages/js/Chargeperday'
import { useAuth } from '../../../../app/modules/auth'
import { Link } from 'react-router-dom'

export interface sessionUpdates {
  chargeboxid: String
  connectorid: String
  sessions: string
}
export const SessionTable = {
  heading: 'Device Transactions',
  subheading: 'Recent 5 Sessions',
  transactionId: 'Transacation Id',
  userId: 'User Id',
  totalwh: 'Total KWh',
  price: 'Total Amount',
  charginDuration: 'Duration(Mins)',
}

const DashboardTransactionTable = ({ className, transactions }) => {
  const { currentUser } = useAuth()
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{SessionTable.heading}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{SessionTable.subheading}</span>
        </h3>
      </div>
      <div className='card-body py-2'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-100px'>{SessionTable.transactionId}</th>
                <th className='min-w-110px'>ChargeBoxId</th>
                <th className='min-w-50px'>{SessionTable.userId}</th>
                <th className='min-w-50px'>{SessionTable.totalwh}</th>
                <th className='min-w-100px'>{SessionTable.price}</th>
                <th className='min-w-100px'>{SessionTable.charginDuration}</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item: any, index: number) => {
                const updatedAt = new Date(item.updatedAt)
                const createdAt = new Date(item.createdAt)
                const timeDifference = updatedAt.getTime() - createdAt.getTime()
                const secondsDifference = timeDifference / 1000
                console.log('Dashboard transactions', item)
                return (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.transactionId}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item.stationId}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.userId}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {(item?.total_wh / 1000).toFixed(3)}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item?.price}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {Math.ceil(secondsDifference / 60)}
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {currentUser?.group === '1' && (
            <div className='d-flex justify-content-end'>
              <Link to='/transactions'>...More</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { DashboardTransactionTable }


