import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {getRequest} from '../Helpers/Requests'
import StationDetailsAmenities from './StationDetailsAmenities'
import StationImages from './StationImages'
import HubDeviceSpecification from './HubDeviceSpecification'

const Heading = {
  heading: 'StationDetails_Zect',
  heading1: 'Details',
  heading2: 'Device Configuration',
  heading3: 'Device Transaction',
  heading4: 'Device Logs',
  heading5: 'Amenties',
  heading6: 'Station Images',
  heading7: 'Device Management',
}

const HubDetails = () => {
  const [stationAmenities, setStationAmenities] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [stationName, setStationName] = useState('')
  const [stationImages, setStationImages] = useState<any>([])
  const {pointId} = useParams()

  // Fetch station details and initialize state
  useEffect(() => {
    const getStation = async () => {
      try {
        const res = await getRequest(`/hub/${pointId}`)
        if (res && res.data) {
          const station = res.data.data.hub
          setStationName(station.stationName)
          setStationAmenities(station.amenities)
          setStationImages(station.images)
        }
      } catch (error) {
        console.error('Error fetching station details:', error)
      }
    }
    getStation()
  }, [pointId])

  useEffect(() => {
    const storedTab = localStorage.getItem('selectedTab')
    if (storedTab !== null) {
      setActiveTab(parseInt(storedTab))
    }
  }, [])

  const handleTabClick = (index: any) => {
    setActiveTab(index)
    localStorage.setItem('selectedTab', index.toString())
  }

  useEffect(() => {
    handleTabClick(0)
  }, [])
  return (
    <div>
      <div className='text-end mb-5'>
        <Link
          to='/Chargepoint'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <KTIcon iconName='arrow-left' className='fs-1' />
        </Link>
      </div>
      <div className='card card-custom'>
        <div className='card-body mt-5'>
          <h1 className='mb-5'>{stationName}</h1>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5'>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_4'
                onClick={() => handleTabClick(0)}
              >
                {Heading.heading1}
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 5 ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_8'
                onClick={() => handleTabClick(5)}
              >
                Station Amenities
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 6 ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_9'
                onClick={() => handleTabClick(6)}
              >
                {Heading.heading6}
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div
              className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}
              id='kt_tab_pane_4'
              role='tabpanel'
            >
              <HubDeviceSpecification />
            </div>

            <div
              className={`tab-pane fade ${activeTab === 5 ? 'show active' : ''}`}
              id='kt_tab_pane_8'
              role='tabpanel'
            >
              <StationDetailsAmenities amenities={stationAmenities} />
            </div>
            <div
              className={`tab-pane fade ${activeTab === 6 ? 'show active' : ''}`}
              id='kt_tab_pane_9'
              role='tabpanel'
            >
              <StationImages images={stationImages} stationId={pointId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HubDetails
