// /* eslint-disable */
import React from 'react'
import {TableWidgetCS} from '../../../_metronic/partials/widgets/tables/TableWidgetCS'

type Props = {
  stationId: string
}

function StationChargeSessions({stationId}: Props) {
  return (
    <div className='mt-10'>
      <TableWidgetCS className='mt-0' stationId={stationId} />
    </div>
  )
}

export default StationChargeSessions
