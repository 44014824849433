/* eslint-disable */
import React from 'react'
import {TableWidgetDT} from '../../../_metronic/partials/widgets/tables/TablesWidgetDT'

const DeviceTransaction = ({stationId}) => {
  return (
    <div className='mt-10'>
      <TableWidgetDT DT={DeviceTransaction} className='mt-0' stationId={stationId} />
    </div>
  )
}

export default DeviceTransaction
